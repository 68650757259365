import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { StoreType } from 'polotno/model/store';
import { Bars } from 'react-loader-spinner';

import placeholderImg from '../../../assets/images/placeholder.png';
import { useTemplatesList } from '../../../hooks/sidebar-hooks';
import { ImageThumbnail } from '../../_common/ImageThumbnail';
import { API_ENDPOINTS, REACT_APP_S3_BUCKET } from '../../../constants/api';
import './styles.scss';
import { isEmpty } from 'lodash';
import { TabItem, Tabs } from '../../_common/Tabs';
import { ApplyTemplate } from './ApplyTemplate';

interface TemplatesProps {
  store: StoreType;
  typeId: string;
}

enum MENU_ITEMS {
  TEMPLATES = 'templates',
  FAVOURITES = 'favourites',
}

export const Templates = ({ store, typeId }: TemplatesProps): JSX.Element => {
  const [selectedMenu, setSelectedMenu] = useState<MENU_ITEMS>(MENU_ITEMS.TEMPLATES);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const { data: templatesList, isLoading } = useTemplatesList(typeId);
  const [aspectRatio, setAspectRatio] = useState<number>(1);

  const pageThumbnailMinWidth = 120;
  const pageThumbnailMinHeight = pageThumbnailMinWidth * aspectRatio;

  useEffect(() => {
    if (!isEmpty(templatesList) && templatesList[0].content) {
      const content = JSON.parse(templatesList[0].content);
      const aspectRatio = content.height / content.width;
      setAspectRatio(aspectRatio);
    }
  }, [templatesList]);

  const handleSelectMenu = (item: string) => {
    setSelectedMenu(item as MENU_ITEMS);
  };

  const handleApplyTemplateBack = () => {
    setSelectedTemplate(null);
  };

  const tabItems = useMemo(
    (): TabItem[] => [
      {
        title: 'TEMPLATES',
        code: MENU_ITEMS.TEMPLATES,
      },
      // {
      //   title: 'FAVOURITES',
      //   code: MENU_ITEMS.FAVOURITES,
      // },
    ],
    [],
  );

  const TabContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="loading">
          <Bars ariaLabel="loading-indicator" color="#ACC9D8" wrapperClass="bars" />
        </div>
      );
    }

    return (
      <div className="templates-content__content">
        {templatesList.map((template: any, index: number) => {
          if (typeof template.content !== 'string') {
            return;
          }

          // Prepare thumbnail
          const imageUrl = `${REACT_APP_S3_BUCKET}/${API_ENDPOINTS.TEMPLATE_THUMBS}/template/${template.uuid}/${template.thumbnail}`;
          const imgStyle: React.CSSProperties = {
            width: `${pageThumbnailMinWidth}px`,
            height: '100%',
            minHeight: `${pageThumbnailMinHeight}px`,
            objectFit: 'contain',
          };

          // Get page count
          const parsedContent = JSON.parse(template.content);
          const totalPages = parsedContent?.pages?.length || 1;

          return (
            <button key={`template-${index}`} className="item-btn" onClick={() => setSelectedTemplate(template)}>
              <div className="item-btn__imgContainer">
                <Suspense
                  fallback={<img src={placeholderImg} style={imgStyle} className="item-btn__image" alt={template.name} />}
                >
                  <ImageThumbnail source={imageUrl} style={imgStyle} className="item-btn__image" alt={template.name} />
                </Suspense>
              </div>
              <div className="item-btn__text text-title">{template.name}</div>
              <div className="item-btn__text text-pages">1 of {totalPages}</div>
            </button>
          );
        })}
      </div>
    );
  }, [templatesList, isLoading, pageThumbnailMinHeight]);

  // Template Details
  if (selectedTemplate !== null) {
    return <ApplyTemplate template={selectedTemplate} onBack={handleApplyTemplateBack} store={store} />;
  }

  // Template tabs
  return (
    <div className="templates-content">
      <Tabs setSelectedMenu={handleSelectMenu} selectedMenu={selectedMenu} items={tabItems} />
      {TabContent}
    </div>
  );
};
