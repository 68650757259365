import { useMemo, useState } from 'react';
import { StoreType } from 'polotno/model/store';

import { StockImages } from './StockImages';
import { ImageBrowser } from './ImageBrowser';

import './styles.scss';
import { TabItem, Tabs } from '../../_common/Tabs';
import { AssetTypes } from '../../../constants/common';

interface ImageProps {
  store: StoreType;
}

enum MENU_ITEMS {
  USER = 'user',
  OFFICE = 'office',
  GROUP = 'group',
  STOCK = 'stock',
}

export const Images = ({ store }: ImageProps): JSX.Element => {
  const [selectedMenu, setSelectedMenu] = useState<MENU_ITEMS>(MENU_ITEMS.USER);

  const handleSelectMenu = (item: string) => {
    setSelectedMenu(item as MENU_ITEMS);
  };

  const tabItems = useMemo(
    (): TabItem[] => [
      {
        title: 'IMAGES',
        code: MENU_ITEMS.USER,
      },
      {
        title: 'OFFICE',
        code: MENU_ITEMS.OFFICE,
      },
      {
        title: 'GROUP',
        code: MENU_ITEMS.GROUP,
      },
      {
        title: 'STOCK',
        code: MENU_ITEMS.STOCK,
      },
    ],
    [],
  );

  const Content = useMemo(() => {
    switch (selectedMenu) {
      case MENU_ITEMS.OFFICE:
        return <ImageBrowser store={store} type={AssetTypes.CLIENT} />;
      case MENU_ITEMS.GROUP:
        return <ImageBrowser store={store} type={AssetTypes.GROUP} />;
      case MENU_ITEMS.STOCK:
        return <StockImages store={store} />;
      case MENU_ITEMS.USER:
      default:
        return <ImageBrowser store={store} type={AssetTypes.USER} />;
    }
  }, [selectedMenu, store]);

  return (
    <div className="images-content">
      <Tabs setSelectedMenu={handleSelectMenu} selectedMenu={selectedMenu} items={tabItems} />
      <div className="images-tab-content">{Content}</div>
    </div>
  );
};
