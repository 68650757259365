import React, { ChangeEvent, useState } from 'react';
import { Form, Modal, ModalHeader, ModalBody, ModalTitle, ModalFooter, Button } from 'react-bootstrap';

import './styles.scss';

type DialogProps = {
  title: string;
  placeholder?: string;
  type?: 'input' | 'prompt';
  open: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
  closeText?: string;
  submitText?: string;
  message?: string;
  centered?: boolean;
  closeButtonVariant?: 'primary' | 'secondary' | 'danger' | 'success';
  submitButtonVariant?: 'primary' | 'secondary' | 'danger' | 'success';
};

export const Dialog = ({
  title = '',
  placeholder = '',
  type = 'input',
  open,
  onClose,
  onSubmit,
  closeText = 'Cancel',
  submitText = 'Create',
  message = '',
  centered = true,
  submitButtonVariant = 'primary',
  closeButtonVariant = 'primary',
  ...props
}: DialogProps): JSX.Element => {
  const [value, setValue] = useState<string>('');

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (type === 'input') {
      if (value) {
        onSubmit(value);
      } else {
        onClose();
      }
      setValue('');
    } else {
      onSubmit(value);
    }
  };

  const handleClose = () => {
    setValue('');
    onClose();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    // <ThemeProvider theme={darkTheme}>
    //   <MUIDialog open={open} onClose={onClose}>
    //     <DialogTitle>{title}</DialogTitle>
    //     <DialogContent>
    //       {type === 'input' ? (
    //         <form onSubmit={handleSubmit}>
    //           <TextField
    //             autoFocus
    //             margin="dense"
    //             id="name"
    //             label="Folder name"
    //             type="text"
    //             fullWidth
    //             variant="standard"
    //             value={value}
    //             onChange={handleChange}
    //           />
    //         </form>
    //       ) : (
    //         <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
    //       )}
    //     </DialogContent>
    //     <DialogActions>
    //       <Button onClick={handleClose}>{closeText}</Button>
    //       <Button onClick={handleSubmit}>{submitText}</Button>
    //     </DialogActions>
    //   </MUIDialog>
    // </ThemeProvider>

    <Modal show={open} onHide={onClose} centered={centered} {...props} className="custom-dialog">
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {type === 'input' ? (
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Folder Name</Form.Label>
              <Form.Control autoFocus type="text" value={value} onChange={handleChange} />
            </Form.Group>
          </Form>
        ) : (
          <p id="alert-dialog-description">{message}</p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button variant={closeButtonVariant} onClick={handleClose}>
          {closeText}
        </Button>
        <Button variant={submitButtonVariant} onClick={handleSubmit}>
          {submitText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
