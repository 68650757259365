import { ChangeEvent, useState } from 'react';
import { reduce, partition, isEmpty } from 'lodash';
import { StoreType } from 'polotno/model/store';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Search } from 'react-feather';
import { Bars } from 'react-loader-spinner';

import { API } from '../../../../api';
import { useInfiniteStockImages } from '../../../../hooks/sidebar-hooks';
import './styles.scss';

interface StockImageProps {
  store: StoreType;
}

export const StockImages = ({ store }: StockImageProps): JSX.Element => {
  const [keyword, setKeyword] = useState<string>('');
  const { data, isLoading, refetch, remove, fetchNextPage, hasNextPage } = useInfiniteStockImages(keyword || 'real estate');

  const handleSearch = (e: ChangeEvent<any>) => {
    e.preventDefault();

    remove();
    setKeyword(e.target.search.value);
  };

  const getContent = () => {
    if (isLoading) {
      return (
        <div className="loading">
          <Bars ariaLabel="loading-indicator" color="#ACC9D8" wrapperClass="bars" />
        </div>
      );
    }

    const insertImage = (url: string) => {
      API.post(`/unsplash/download`, {
        url,
      }).then((response) => {
        store.activePage?.addElement({
          type: 'image',
          x: 0,
          y: 0,
          src: response.data,
        });
      });
    };

    // Columnize combo - split array into two but maintain order
    const withIndex = (fn: Function) => {
      let index = 0;
      return (thing: any) => fn(thing, index++);
    };

    const parseImageList = () => {
      if (!data || isEmpty(data?.pages)) return [];
      else {
        let imgList = reduce(data.pages, (prev: any, curr: any) => (isEmpty(prev) ? curr : prev.concat(curr)), []);
        imgList = partition(
          imgList,
          withIndex((_thing: any, idx: number) => idx % 2),
        );
        return imgList;
      }
    };
    // .toArray();

    return (
      <InfiniteScroll
        dataLength={data?.pages.length || 0}
        next={fetchNextPage}
        hasMore={hasNextPage ? true : false}
        scrollableTarget="scrollableSidebar"
        loader={
          <div className="loading">
            <Bars ariaLabel="loading-indicator" color="#ACC9D8" wrapperClass="bars" />
          </div>
        }
        endMessage="No more results"
        refreshFunction={refetch}
      >
        <div className="imagecolumns">
          {parseImageList().map((imageGroup: any, index: number) => {
            return (
              <div className="imagecolumns__column" key={`imagecolumn-${index}`}>
                {imageGroup.map((image: any, index2: number) => (
                  <img
                    key={`column-${index}-img-${index2}`}
                    alt="Stock"
                    className="img-item"
                    src={image?.urls?.thumb}
                    onClick={() => insertImage(image?.links?.download_location)}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
    );
  };

  return (
    <div className="stock-content">
      <form className="searchbar" onSubmit={handleSearch}>
        <input name="search" className="searchbar__input" type="text" placeholder="Search" />
        <button type="submit" className="searchbar__button">
          <Search size="18" />
        </button>
      </form>
      {getContent()}
    </div>
  );
};
