import React, { Suspense, useState } from 'react';
import { ChevronLeft, Plus } from 'react-feather';

import './styles.scss';
import placeholderImg from '../../../../assets/images/placeholder.png';
import { API_ENDPOINTS, REACT_APP_S3_BUCKET } from '../../../../constants/api';
import { ImageThumbnail } from '../../../_common/ImageThumbnail';
import { cloneDeep, isEmpty } from 'lodash';
import { StoreType } from 'polotno/model/store';
import { Dialog } from '../../../_common/Dialog';
import { toast } from 'react-toastify';

type ApplyTemplateProps = {
  template: any;
  onBack: () => void;
  store: StoreType;
};

export const ApplyTemplate = ({ template, onBack, store }: ApplyTemplateProps): JSX.Element => {
  // TODO: Other content
  const { name, content } = template;
  const parsedContent = JSON.parse(content);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedPage, setSelectedPage] = useState<any>(null);
  const [openPageDialog, setOpenPageDialog] = useState<boolean>(false);

  const handleOpenDialog = () => {
    if (!isEmpty(parsedContent.pages)) {
      setOpenDialog(true);
    } else {
      toast.error('Invalid template.', {
        hideProgressBar: true,
      });
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleApplyToAll = async () => {
    try {
      await store.loadJSON(parsedContent);
      setOpenDialog(false);
      toast.success('Successfully applied template.', {
        hideProgressBar: true,
      });
    } catch (e) {
      setOpenDialog(false);
      toast.error('Error applying template.', {
        hideProgressBar: true,
      });
    }
  };

  const handleSelectPage = (page: any) => () => {
    setOpenPageDialog(true);
    setSelectedPage(page);
  };

  const handleApplyToActivePage = async () => {
    const newPage = cloneDeep(selectedPage);
    delete newPage.id;
    newPage.width = 'auto';
    newPage.height = 'auto';

    store.activePage?.set(newPage);
    setOpenPageDialog(false);
  };

  const handleInsertNewPage = async () => {
    const newPage = cloneDeep(selectedPage);
    delete newPage.id;
    newPage.width = 'auto';
    newPage.height = 'auto';

    store.addPage(newPage);
    setOpenPageDialog(false);
  };

  const renderItem = (page: any, index: number) => {
    const imageUrl = '';
    // const imageUrl = `${REACT_APP_S3_BUCKET}/${API_ENDPOINTS.TEMPLATE_THUMBS}/template/${page.uuid}/${page.thumbnail}`;
    return (
      <button key={`template-${index}`} className="applytemplate__pageitem" onClick={handleSelectPage(page)}>
        <Suspense fallback={<img src={placeholderImg} className="pageTemplateImage" alt={page.name || ''} />}>
          <ImageThumbnail source={imageUrl} className="pageTemplateImage" alt={page.name || ''} />
        </Suspense>
        <div className="pageTemplateHoverOverlay" />
        <div className="pageTemplateHoverContainer">
          <div className="pageTemplateHoverContainer--add-icon">
            <Plus color="white" />
          </div>
        </div>
      </button>
    );
  };

  return (
    <div className="applytemplate">
      <button className="applytemplate__header" onClick={onBack}>
        <div className="applytemplate__header__button">
          <ChevronLeft color="#acc9d8" />
        </div>
        <div className="applytemplate__header__title">{name}</div>
      </button>
      <button className="applytemplate__apply" onClick={handleOpenDialog}>
        <div className="applytemplate__apply__title">Apply all pages</div>
      </button>
      <div className="applytemplate_pagelist">
        {parsedContent.pages && parsedContent.pages.map((page: any, index: number) => renderItem(page, index))}
      </div>
      <Dialog
        title="Apply Template"
        type="prompt"
        message="This overwrites the current design. Do you want to apply the template?"
        submitText="Apply"
        closeText="Cancel"
        open={openDialog}
        onClose={handleClose}
        onSubmit={handleApplyToAll}
      />
      <Dialog
        title="Apply Template Page"
        type="prompt"
        message="Do you want to apply the template page to the active page or add a new page?"
        submitText="Apply Page"
        closeText="Add Page"
        open={openPageDialog}
        onClose={handleInsertNewPage}
        onSubmit={handleApplyToActivePage}
      />
    </div>
  );
};
