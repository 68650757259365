import React from 'react';
import { StoreType } from 'polotno/model/store';
import './styles.scss';

interface TextProps {
  store: StoreType;
}

export const Text = ({ store }: TextProps): JSX.Element => {
  const addText = (type: string) => {
    switch (type) {
      case 'heading':
        store.activePage?.addElement({
          type: 'text',
          x: 50,
          y: 50,
          fill: 'black',
          text: 'hello',
          fontSize: 125,
          width: store.width,
        });
        break;
      case 'subheading':
        store.activePage?.addElement({
          type: 'text',
          x: 50,
          y: 50,
          fill: 'black',
          text: 'hello',
          fontSize: 90,
          width: store.width,
        });
        break;
      case 'bodytext':
        store.activePage?.addElement({
          type: 'text',
          x: 50,
          y: 50,
          fill: 'black',
          text: 'hello',
          fontSize: 80,
          width: store.width,
        });
        break;
      case 'text':
        store.activePage?.addElement({
          type: 'text',
          x: 50,
          y: 50,
          fill: 'black',
          text: 'hello',
          fontSize: 20,
          width: store.width,
        });
        break;
    }
  };

  return (
    <div className="text-content">
      <h5 className="sidebar-header">TEXT</h5>
      <div className="text-items">
        <p className="sub-heading">Click text to add to page</p>
        <button className="text-btn" onClick={() => addText('heading')}>
          <h3 className="heading">Add a heading</h3>
        </button>
        <button className="text-btn" onClick={() => addText('subheading')}>
          <h4 className="subheading">Add a subheading</h4>
        </button>
        <button className="text-btn" onClick={() => addText('bodytext')}>
          <h5 className="bodytext">Add a body text</h5>
        </button>
        <button className="text-btn" onClick={() => addText('text')}>
          <p className="normaltext">Add a text</p>
        </button>
      </div>
    </div>
  );
};
