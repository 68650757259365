export const { REACT_APP_ENDPOINT, REACT_APP_POLOTNO_PUBLIC_KEY, REACT_APP_S3_BUCKET, REACT_APP_HOME_URL } = process.env;

export const API_ENDPOINTS = {
  // Entities
  TEMPLATES: 'designs/templates',
  ELEMENTS: 'designs/elements',
  DESIGNS: 'designs',
  SHAPES: 'shapes',

  // resources/assets
  TEMPLATE_THUMBS: 'resources/pro/thumbnails',
  FILES: 'files',
  FOLDERS: 'files/folders',

  // property search
  PROPERTY_SEARCH: 'listings/search',

  // merge fields
  MERGE_FIELDS: 'mergefields',

  // auth
  USER: 'auth/user',
};
