import React, { Key, MutableRefObject, ReactElement } from 'react';
import { ControlledMenu, MenuItem, MenuDivider, ControlledMenuProps } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import './styles.scss';

export type MenuOption = {
  title: string;
  action: () => void;
  icon?: ReactElement;
  withDivider?: boolean;
};
interface MenuProps extends ControlledMenuProps {
  options: MenuOption[];
  onClose: () => void;
  anchor: MutableRefObject<any>;
  type?: 'general' | 'context';
  title?: string;
  coords?: {
    mouseX: number;
    mouseY: number;
  } | null;
}

export const Menu = ({options, type = 'general', coords = null, anchor, onClose, title = '', ...props} : MenuProps): JSX.Element => {

  // const typeExtraProps =
  //   type === 'general'
  //     ? {
  //         anchorEl: anchor,
  //       }
  //     : {
  //         anchorReference: 'anchorPosition',
  //         anchorPosition: coords !== null ? { top: coords?.mouseY, left: coords?.mouseX } : undefined,
  //       };

  const renderMenuItem = (option: MenuOption, index: Key) => {
    let item = (
      <MenuItem key={`menuItem-${index}`} onClick={option.action}>
        {option.icon && <div className="custom-menu__icon-container">{option.icon}</div>}
        {option.title}
      </MenuItem>
    );
    if (option.withDivider) {
      return (
        <div key={`menuItemContainer-${index}`}>
          {item}
          <MenuDivider />
        </div>
      );
    }
    return item;
  };

  return (
    <ControlledMenu
      id="fade-menu"
      onClose={onClose}
      anchorRef={anchor}
      {...props}
    >
      {title && (
        <h2 className="custom-menu__subtitle2">
          {title}
        </h2>
      )}
      {options.map((option, index) => renderMenuItem(option, index))}
    </ControlledMenu>
  );
};
