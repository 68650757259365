import { ElementType, StoreType } from 'polotno/model/store';
import { useMemo } from 'react';
import { Bars } from 'react-loader-spinner';

import { DesignsElement } from '../../../constants/types/designs';
import { useElementsList } from '../../../hooks/sidebar-hooks';
import './styles.scss';

interface ElementsProps {
  store: StoreType;
  design: {
    id: string | undefined;
    type: string | undefined;
  };
}

export const Elements = ({ store }: ElementsProps): JSX.Element => {
  let { data: elementsList, isLoading } = useElementsList();

  const insertElement = (store: StoreType, designsElement: DesignsElement) => {
    const elementIds: string[] = [];
    const content = JSON.parse(designsElement.content);

    if (content?.pages[0]?.children?.length) {
      content?.pages[0]?.children.forEach((element: ElementType) => {
        element.id = `${element.id}-${Math.random()}`;

        store?.activePage?.addElement(element);
        elementIds.push(element.id);
      });
    }

    store.selectElements(elementIds);
  };

  const Content = useMemo(() => {
    if (isLoading) {
      return (
        <div className="loading">
          <Bars ariaLabel="loading-indicator" color="#ACC9D8" wrapperClass="bars" />
        </div>
      );
    }
    return (
      <div className="elements-content__content">
        {elementsList.map((designsElement: DesignsElement, index: number) => {
          if (designsElement.content) {
            return (
              <button key={`element-${index}`} className="item-btn" onClick={() => insertElement(store, designsElement)}>
                <div className="item-btn-text">{designsElement.name}</div>
              </button>
            );
          }
          return null;
        })}
      </div>
    );
  }, [elementsList, isLoading, store]);

  return (
    <div className="elements-content">
      <h5 className="sidebar-header">ELEMENTS</h5>
      {Content}
    </div>
  );
};
