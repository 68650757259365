import { AxiosInstance } from 'axios';
import { REACT_APP_ENDPOINT } from '../constants/api';

const apiInterceptor = (instance: AxiosInstance) => {
  // Interceptors
  instance.interceptors.request.use(
    async (config) => {
      config.baseURL = REACT_APP_ENDPOINT;
      return config;
    },
    (error) => {
      console.log('Error in request: ', error);
      // Do something with request error
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    async (response) => {
      // Do something with response data
      return {
        data: response.data.data,
        status: response.status,
      };
    },
    (error) => {
      console.log('Error in request: ', error);
      // Do something with request error
      return Promise.reject(error);
    },
  );
};

export default apiInterceptor;
