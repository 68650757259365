import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINTS, REACT_APP_ENDPOINT } from '../constants/api';
import { SearchFilters } from '../constants/types/search';

const axios = require('axios').default;

// Custom axios instance, interceptor-free
const instance: AxiosInstance = axios.create({
  baseURL: REACT_APP_ENDPOINT,
  timeout: 30 * 1000,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
});

const fetchPropertySearch = async (filters: SearchFilters) => {
  const { data } = await instance.post(API_ENDPOINTS.PROPERTY_SEARCH, filters);
  return data;
};

const fetchMergeFields = async () => {
  const { data } = await instance.get(API_ENDPOINTS.MERGE_FIELDS);
  return data;
};

export const usePropertySearchQuery = (filters: SearchFilters) => {
  return useQuery(['propertySearch', filters], () => fetchPropertySearch(filters));
};

export const useGetMergeFields = () => {
  return useQuery(['mergeFields'], () => fetchMergeFields());
};
