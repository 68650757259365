import React, { useMemo } from 'react';
import { StoreType } from 'polotno/model/store';
import { useShapesList } from '../../../../hooks/sidebar-hooks';
import { ShapeModel } from '../../../../constants/types/designs';
import { Bars } from 'react-loader-spinner';

import './styles.scss';

export const LinesAndShapes = ({ store }: { store: StoreType }): JSX.Element => {
  const { isLoading, data: shapesList } = useShapesList();

  const Content = useMemo(() => {
    const addShape = (url: string) => {
      store.activePage?.addElement({
        type: 'svg',
        src: `${url}?v=1`,
        x: 0,
        y: 0,
        keepRatio: false,
      });
    };

    if (isLoading)
      return (
        <div className="loading">
          <Bars ariaLabel="loading-indicator" color="#ACC9D8" wrapperClass="bars" />
        </div>
      );
    return (
      <div className="linesandshapes-content__content">
        {shapesList.map((shape: ShapeModel, index: number) => {
          return (
            <div key={`shape-${index}`} className="item">
              <button className="shapes-btn" type="button" onClick={() => addShape(shape.url)}>
                <img alt={shape.name} src={shape.url} />
              </button>
            </div>
          );
        })}
      </div>
    );
  }, [shapesList, isLoading, store]);

  return <div className="linesandshapes-content">{Content}</div>;
};
