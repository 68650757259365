import React, { Fragment, ReactElement, forwardRef } from 'react';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import './styles.scss';
import { TailSpin } from 'react-loader-spinner';

interface Props {
  title: string;
  htmlType?: 'button' | 'submit' | 'reset';
  type?: 'primary' | 'secondary' | 'white' | 'empty';
  rounded?: boolean;
  tooltip?: string;
  disabled?: boolean;
  isLoading?: boolean;
  icon?: ReactElement;
  containerClassName?: string;
  textClassName?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ButtonComponent = (props: Props, ref: any): JSX.Element => {
  const {
    title,
    htmlType = 'button',
    type = 'primary',
    disabled,
    onClick,
    tooltip,
    rounded = false,
    icon = undefined,
    isLoading,
    containerClassName = '',
    textClassName = '',
  } = props;
  const wrapClass = classNames(
    'button',
    containerClassName,
    {
      [`button--${type}`]: !!type,
    },
    { rounded: rounded },
  );

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  const getContent = () => {
    // TODO: fixed width for isLoading
    if (isLoading) {
      return <TailSpin color={type === 'primary' ? 'white' : '#223646'} height={20} />;
    }
    return (
      <Fragment>
        {icon && <div className="icon-container">{icon}</div>}
        <p className={`${textClassName} button--text`}>{title}</p>
      </Fragment>
    );
  };

  return (
    <Button
      ref={ref}
      variant="primary"
      title={tooltip}
      type={htmlType}
      className={wrapClass}
      disabled={isLoading || disabled}
      onClick={handleClick}
    >
      {getContent()}
    </Button>
  );
};

const CustomButton = React.memo(forwardRef(ButtonComponent));

export { CustomButton as Button };
