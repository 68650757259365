import React from 'react';
// import './assets/styles/main.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Routes
import { Layout } from './components/Layout';
import { DesignerScreen } from './pages/auth/DesignerScreen';
import { Landing } from './pages/nonauth/Landing';
import { SignIn } from './pages/nonauth/SignIn';

// Utils
import { isUserLoggedIn } from './utils/user-utils';

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/auth" element={<SignIn />} />
          <Route
            path="/design/:id/:type"
            element={
              <RequireAuth>
                <DesignerScreen />
              </RequireAuth>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  if (!isUserLoggedIn()) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default AppRouter;
