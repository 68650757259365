import React, { Suspense, useState } from 'react';
import { PageType, StoreType } from 'polotno/model/store';
// Assets
import styles from './PageSidebar.module.scss';
import { ChevronRight, Copy, Plus, Trash2 } from 'react-feather';
import { TailSpin } from 'react-loader-spinner';
import { cloneDeep } from 'lodash';
import { ImageThumbnail } from '../_common/ImageThumbnail';
import placeholderImg from '../../assets/images/placeholder.png';
import { API_ENDPOINTS, REACT_APP_S3_BUCKET } from '../../constants/api';
import { EntityTypes } from '../../constants/common';
import { Dialog } from '../_common/Dialog';

import { observer } from 'mobx-react-lite';

interface PageSidebarProps {
  entityId: string;
  entityType: EntityTypes;
  store: StoreType;
  isLoading?: boolean;
  hidePanel: () => void;
  setReload?: any;
  reload?: boolean;
  entityData: any;
}

export const PageSidebar = observer(({
  store,
  isLoading,
  hidePanel,
  reload,
  setReload,
  entityId,
  entityType,
  entityData
}: PageSidebarProps): JSX.Element => {
  const pageCount = store.pages.length;
  const [modalSelect, setModalSelect] = useState<string>('');

  const pageAspectRatio = store.height / store.width;
  const pageThumbnailMinWidth = 170;
  const pageThumbnailMinHeight = pageThumbnailMinWidth * pageAspectRatio;

  // sidebar funcs.
  const handleSelect = (page: PageType) => {
    store.selectPage(page.id);
    setReload(!reload);
  };

  const handleOnDuplicate = (page: PageType) => {
    const newPage: any = cloneDeep(page);
    delete newPage.id;
    store.addPage({
      ...newPage,
    });
  };

  const handleAddPage = () => {
    store.addPage({
      width: entityData?.type?.width,
      height: entityData?.type?.height,
    });
  };

  // Modal funcs.
  const handleModalAction = (action: 'close' | 'confirm' | 'cancel' | 'open', pageId?: string) => {
    switch (action) {
      case 'open':
        if (pageId) setModalSelect(pageId);
        break;
      case 'close':
      case 'cancel':
        setModalSelect('');
        break;
      case 'confirm': {
        if (modalSelect) store.deletePages([modalSelect]);
        setModalSelect('');
      }
    }
  };

  if (isLoading) {
    return (
      <div className={styles.sidebar}>
        <div className={styles.headerLoading}>
          <button className={styles.backButton} onClick={() => hidePanel()}>
            <ChevronRight height={20} />
          </button>
        </div>
        <div className={styles.loader}>
          <TailSpin height={50} color="#223646" />
        </div>
      </div>
    );
  }

  const renderPageItem = (page: PageType, index: number) => {
    const isActive = page.id === store._activePageId;
    const imageUrl = `${REACT_APP_S3_BUCKET}/${API_ENDPOINTS.TEMPLATE_THUMBS}/${entityType}/${entityId}/${page.id}.jpg`;

    const imgStyle: React.CSSProperties = {
      width: `${pageThumbnailMinWidth}px`,
      height: '100%',
      minHeight: `${pageThumbnailMinHeight}px`,
      objectFit: 'contain',
    };
    return (
      <button
        key={`page-item-${index}`}
        className={`${styles.pageItem} ${isActive && 'active'}`}
        onClick={() => handleSelect(page)}
      >
        <div className={styles.pageNumber}>{index + 1}</div>
        <div className="pageThumbnail">
          <Suspense
            fallback={<img src={placeholderImg} style={imgStyle} className="pageThumbnail__page-img" alt={`Page ${index}`} />}
          >
            <ImageThumbnail source={imageUrl} style={imgStyle} className="pageThumbnail__page-img" alt={`Page ${index}`} />
          </Suspense>
          <div className="floating-menu">
            <div className="floating-menu__button">
              <Copy size={15} color="#fff" onClick={() => handleOnDuplicate(page)} />
            </div>
            <div className="floating-menu__button">
              <Trash2 size={15} color="#fff" onClick={() => handleModalAction('open', page.id)} />
            </div>
          </div>
        </div>
      </button>
    );
  };

  const getModal = () => {
    return (
      <Dialog
        open={!!modalSelect}
        onClose={() => handleModalAction('close')}
        onSubmit={() => handleModalAction('confirm')}
        centered
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        title="Confirm Delete"
        type="prompt"
        closeText="Cancel"
        submitText="Delete"
        message="Are you sure you want to delete this page?"
        submitButtonVariant="danger"
      />
    );
  };

  return (
    <div className={styles.sidebar}>
      {/* Top Header/Back */}
      <div className={styles.header}>
        <div className={styles.headerText}>
          PAGES <span className={styles.headerTextSpan}> ({pageCount})</span>
        </div>
        <button className={styles.backButton} onClick={() => hidePanel()}>
          <ChevronRight height={20} />
        </button>
      </div>

      {/* Pages List */}
      <div className={styles.pagesList}>{store.pages.map((page, index) => renderPageItem(page, index))}</div>

      {/* Footer/Add Page */}
      <button className={styles.addPageButton} onClick={handleAddPage}>
        <div className={styles.addPageIcon}>
          <Plus color="#223646" />
        </div>
        <div className={styles.addPageText}>Add Page</div>
      </button>
      {getModal()}
    </div>
  );
});
