import { StoreType } from 'polotno/model/store';
import { EyeOff } from 'react-feather';
import { Button } from '../_common/Button';
import './styles.scss';
import * as svg from "polotno/utils/svg";
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

interface GridToolbarProps {
    store: StoreType,
    isHidden: boolean,
    onHideGrid: () => void
}

const GridToolbar = observer(({ store, isHidden, onHideGrid } : GridToolbarProps) => {

    const [gridValues, setGridValues] = useState({ rows: 5, columns: 5 });

    const handleInput = (e: any) => {
        var value = e.target.value;

        setGridValues({ ...gridValues, [e.target.name]: value });
    }

    useEffect(() => {

        if (isHidden) {
            clearGrid();
        } else {
            generateGrid(gridValues.rows ? gridValues.rows : 1, gridValues.columns ? gridValues.columns : 1);
        }

    }, [isHidden, gridValues, store.activePage])


    return <>
        {!isHidden &&
            <div className='gridToolbar'>

                <div className='gridToolbar__fields'>
                    <label>Rows</label>
                    <input type="number" pattern="[0-9]*" name='rows' value={gridValues.rows} onChange={handleInput} />
                    <label>Columns</label>
                    <input type="number" pattern="[0-9]*" name='columns' value={gridValues.columns} onChange={handleInput} />
                </div>

                <div className='gridToolbar__buttoncol'>
                    <Button
                        title="Hide grid"
                        icon={<EyeOff color="black" size={25} />}
                        type="secondary"
                        onClick={onHideGrid}
                    />
                </div>

            </div>}
    </>

    function createDot(x: any, y: any) {
        const template = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1"></svg>`;
        const url = svg.svgToURL(template);
        store.activePage?.addElement({
            x,
            y,
            type: "svg",
            width: 1,
            height: 1,
            src: url,
            name: "grid",
            selectable: false
        });
    }

    function clearGrid() {
        const gridElements = store.activePage?.children.filter(
            (child) => child.name === "grid"
        );

        if (!gridElements) return;

        const ids = gridElements?.map((el) => el.id);
        store.deleteElements(ids as string[]);
    }

    function generateGrid(rows: number, cols: number) {

        if(!store.activePage) return;

        clearGrid();
        const { width, height } = store.activePage;
        const dx = (width as number) / rows;
        const dy = (height as number) / cols;
        // generate svg data for grid image
        const template = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}">
        ${[...Array(rows - 1)]
                .map(
                    (_, index) =>
                        `<line x1="${dx * (index + 1)}" y1="0" x2="${dx * (index + 1)
                        }" y2="${height}" stroke="blue" stroke-width="2"/>`
                )
                .join("")}
            ${[...Array(cols - 1)]
                .map(
                    (_, index) =>
                        `<line x1="0" y1="${dy * (index + 1)}" x2="${width}" y2="${dy * (index + 1)
                        }" stroke="blue" stroke-width="2"/>`
                )
                .join("")}
        </svg>`;

        // add grid image into the page
        const url = svg.svgToURL(template);

        store.activePage?.addElement({
            type: "svg",
            width,
            height,
            src: url,
            name: "grid",
            selectable: false,
            opacity: 0.2,
            alwaysOnTop: true
        });

        // add fake elemtns to snap on grid
        [...Array(rows - 1)].forEach((_, index) => {
            createDot(dx * (index + 1), 0);
        });
        [...Array(cols - 1)].forEach((_, index) => {
            createDot(0, dy * (index + 1));
        });
    }

})

export default GridToolbar;