import React, { useMemo, useState } from 'react';
import { StoreType } from 'polotno/model/store';
import './styles.scss';
// import { Select } from '../../Select';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { MergeField } from '../../../constants/types/merge-field';
import { useGetMergeFields } from '../../../hooks/merge-fields-hooks';

interface Props {
  store: StoreType;
  entityData: any;
}

export const MergeFields = ({ store, entityData }: Props): JSX.Element => {
  // states
  const [type, setType] = useState('Office');

  const [searchMergeFieldFilters, setSearchMergeFieldFilters] = useState<any>({category: 'Office'});
  const { data: mergeFieldsData, isLoading: isLoadingMergeFields, isError: isErrorMergeFields } = useGetMergeFields(searchMergeFieldFilters);
  const mergeFields = useMemo(() => mergeFieldsData?.data, [mergeFieldsData]);

  // funcs
  const handleChange = (e: string) => {
    setType(e || 'Office');
    setSearchMergeFieldFilters({category: e});
  };

  const handleClick = async (mergeField: MergeField) => {
    if (mergeField.merge_field.startsWith('{{property.images.')) {
      const propertyImageMergeUrl = `https://resources.designly.com.au/images/prop-photo-${mergeField.merge_field}.png`;
      const { width, height } = await getImageSize(propertyImageMergeUrl);

      const baseSize = width + height;
      const currentSize = entityData?.type?.width + entityData?.type?.height;
      const scale = currentSize / baseSize;

      store.activePage?.addElement({
        type: 'image',
        x: 0,
        y: 0,
        src: propertyImageMergeUrl,
        width: width * scale,
        height: height * scale,
        custom: {
          merge: mergeField.merge_field,
        }
      });
    } else {
      store.activePage?.addElement({
        type: 'text',
        x: 50,
        y: 50,
        fill: 'black',
        text: mergeField.placeholder ?? mergeField.merge_field,
        custom: {
          merge: mergeField.merge_field,
        },
        fontSize: 20,
        width: entityData?.type?.width,
      });
    }
  };

  const getImageSize = (url: string): Promise<any> => {
    return new Promise((resolve) => {
      const img = document.createElement('img');
      img.onload = async () => {
        resolve({
          width: img.width,
          height: img.height,
        });
        img.remove();
      };
      img.src = url;
    });
  }

  return (
    <div className="mergefields-content">
      <h5 className="sidebar-header">MERGE FIELDS</h5>
      <p className="sub-heading">Select type of merge fields</p>
      <div className="select-container">
        {/* <Select options={['Agent', 'Property']} value={type} onChange={handleChange} /> */}
        <DropdownButton title={type} className="custom__dropdown">
          <Dropdown.Item as="button" onClick={() => handleChange('Office')}>
            Office
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => handleChange('User')}>
            User
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => handleChange('Property')}>
            Property
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => handleChange('Property Images')}>
            Property Images
          </Dropdown.Item>
        </DropdownButton>
      </div>
      <div className="mergefield-list">
        {mergeFields?.map((mergeField: MergeField, index: number) => {
          return <button key={`merge-${index}`} className="field-btn" onClick={() => handleClick(mergeField)}>
            <h4 className="field-btn--text">{`{ ${mergeField.name} }`}</h4>
          </button>
        })}
      </div>
    </div>
  );
};
