import { useEffect, useState } from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { API } from '../../../api';
import './styles.scss';

interface IProps {
  show: boolean;
  onClose: () => void;
  reference: string;
  id: string;
}

export const SaveToCategoryModal = ({ show, onClose, reference, id }: IProps) => {
  const [folderOptions, setFolderOptions] = useState<any[]>([]);

  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);

  const [selectedFolderID, setSelectedFolderID] = useState<number>(0);
  const [selectedCategoryID, setSelectedCategoryID] = useState<number | string>(reference);

  const [loading, setLoading] = useState(false);

  const { handleSubmit } = useForm();

  useEffect(() => {
    if (show) {
      setLoading(true);
      loadCategories().then(() => {
        setSelectedCategoryID(reference);
        loadFolders(reference).then(() => setLoading(false))
      });
    }
  }, [show]);

  const loadFolders = async (ID: number | string) => {
    const response = await API.get(`/quickdesigns/groups/${ID}/${reference}`);
    setFolderOptions(
      response.data.map((_data: any) => {
        return { ID: _data.ID, name: _data.name };
      }),
    );
  };

  const loadCategories = async () => {
    var category = {};

    switch (reference) {
      case 'printables': {
        category = {
          ID: reference,
          name: 'Printables',
        };
        break;
      }
      case 'socialmedia': {
        category = {
          ID: reference,
          name: 'Social Media',
        };
        break;
      }
    }

    const response = await API.get(`/customcategories`);
    setCategoryOptions([
      category,
      ...response.data.map((_data: any) => {
        return { ID: _data.ID, name: _data.name };
      }),
    ]);
  };

  const handleOnSubmit = () => {
    setLoading(true);
    API.post(`/designs/templates/${id}/category`, {
      quick_design_group_id: selectedFolderID !== 0 ? selectedFolderID : null,
      reference: selectedCategoryID,
    })
      .then(() => {
        setLoading(false);
        toast.success('Saved', {
          hideProgressBar: true,
        });
        onClose();
      })
      .catch(() => {
        setLoading(false);
        toast.error('Error occurred. Please try again later.', {
          hideProgressBar: true,
        });
      });
  };

  return (
    <>
      <Modal show={show}>
        <ModalHeader>
          <ModalTitle>Move to category</ModalTitle>
        </ModalHeader>

        <ModalBody>
          <Form onSubmit={handleOnSubmit}>
            <Form.Group role="form" className="mb-3">
              <Form.Label>Category</Form.Label>
              <Form.Select disabled={loading} value={selectedCategoryID} onChange={(e: any) => {
                setLoading(true);
                loadFolders(e.target.value).then(() => setLoading(false))
                setSelectedCategoryID(e.target.value);
                setSelectedFolderID(0);
              }}>
                {categoryOptions.map((_category: any) => {
                  return (
                    <option value={_category.ID} key={'category-' + _category.ID}>
                      {_category.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group role="form" className="mb-3">
              <Form.Label>Folder</Form.Label>
              <Form.Select disabled={loading} value={selectedFolderID} onChange={(e: any) => setSelectedFolderID(e.target.value)}>
                <option value="0">---</option>
                {folderOptions.map((_folder: any) => {
                  return (
                    <option value={_folder.ID} key={'folder-' + _folder.ID}>
                      {_folder.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" onClick={onClose} disabled={loading}>
            Close
          </Button>
          <Button variant="primary" disabled={loading} onClick={handleOnSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
