import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINTS, REACT_APP_ENDPOINT } from '../constants/api';

const axios = require('axios').default;

// Custom axios instance, interceptor-free
const instance: AxiosInstance = axios.create({
  baseURL: REACT_APP_ENDPOINT,
  timeout: 30 * 1000,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
});

const fetchMergeFields = async (params = {}) => {
  const { data } = await instance.get(`${API_ENDPOINTS.MERGE_FIELDS}`, {
    params: params
  })
  return data;
};

export const useGetMergeFields = (params: any = {}) => {
  return useQuery(['mergeFields', params], () => fetchMergeFields(params));
};
