import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { API } from '../../../api';
import { API_ENDPOINTS } from '../../../constants/api';

import './styles.scss';

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const SignIn = () => {
  // const navigate = useNavigate();
  const query = useQuery();

  const token = query.get('token');
  const designsTemplateId = query.get('designs_template_id');


  const logoRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    logoRef.current?.classList.remove('opacity0');
  }, []);

  if (token) {
    localStorage.setItem('token', token);
    API.get(API_ENDPOINTS.USER).then((response: any) => {
      localStorage.setItem('probuilder_user', window.btoa(JSON.stringify(response.data)));
      setTimeout(() => {
          logoRef.current?.classList.add('opacity0');
          setTimeout(() => {
            var requestUrl = `/design/${query.get('id')}/${query.get('type')}`;
            if (designsTemplateId) {
              requestUrl += `?designs_template_id=${designsTemplateId}`
            }
            window.location.href = requestUrl;
          }, 950);          
      }, 2000);
    });
  }

  return (
    <div className="signin">
      <img ref={logoRef} src="/logo.svg" className="opacity0"></img>
    </div>
  );
};
