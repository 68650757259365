import React from 'react';
import { useImage } from 'react-image';
import thumbImg from '../../../assets/images/placeholder.png';

type ImageThumbnailProps = {
  source: string;
  className?: string;
  alt?: string;
  style?: any;
};

export const ImageThumbnail = ({ source, className, alt, style = {} }: ImageThumbnailProps): JSX.Element => {
  const { src } = useImage({
    srcList: [source, thumbImg],
  });

  return <img src={src} alt={alt} className={className} style={style} />;
};
