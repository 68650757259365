import React, { useMemo, useState } from 'react';
import { EyeOff, Sliders } from 'react-feather';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { Bars } from 'react-loader-spinner';
import { useImage } from 'react-image';
import _ from 'lodash';

import styles from './PropertySearch.module.scss';
import { PropertySearchSidebar } from './PropertySearchSidebar/PropertySearchSidebar';
import { useGetMergeFields, usePropertySearchQuery } from '../../hooks/property-search-hooks';
import { defaultSearchFilters, SearchFilters } from '../../constants/types/search';
import { getAddressString } from '../../utils/property-utils';
import { Property } from '../../constants/types/property';
import { REACT_APP_S3_BUCKET } from '../../constants/api';
import { MergeField } from '../../constants/types/merge-field';

const placeholderImage =
  'https://s3-ap-southeast-2.amazonaws.com/mydesign3.websiteblue.com/resources/admin/1/images/propphoto_placeholder_two.svg';

// type PropertySearchProps = {
//   store: StoreType;
//   closeSearch: () => void;
// };

export const PropertySearchModal = (props: any): JSX.Element => {
  const { store } = props;
  const [searchFilters, setSearchFilters] = useState<SearchFilters>(defaultSearchFilters);
  const [selectedProperties, setSelectedProperties] = useState<Property[]>([]);
  const { control, handleSubmit } = useForm<SearchFilters>({ defaultValues: defaultSearchFilters });

  // Data
  const { data: propertySearchListData, isLoading, isError, refetch } = usePropertySearchQuery(searchFilters);
  const propertyList = useMemo(() => propertySearchListData?.data, [propertySearchListData]);
  const { data: mergeFieldsData, isLoading: isLoadingMergeFields, isError: isErrorMergeFields } = useGetMergeFields();
  const mergeFields = useMemo(() => mergeFieldsData?.data, [mergeFieldsData]);

  // Methods
  const onSubmit = handleSubmit((data) => {
    setSearchFilters(data);
    refetch();
  });

  const onInsertProperties = () => {
    setSelectedProperties([]);

    selectedProperties.forEach((property, index: number) => {
      if (index !== 0) {
        const newPage: any = _.cloneDeep(store?.activePage);
        delete newPage.id;
        const newPageId = store.addPage({
          ...newPage,
        }).id;
        store?.selectPage(newPageId);
      }

      const elements = store?.activePage?.children;

      if (elements?.length) {
        const replacedMergeFields = replaceMergeFields(property);

        elements?.forEach((element: any) => {
          if (element.type === 'text') {
            let elementMergeFields = String(element.text).match(/{{(.*?)}}/);

            if (
              (!elementMergeFields?.length || (elementMergeFields?.length && elementMergeFields?.length < 2)) &&
              element?.custom?.merge
            ) {
              elementMergeFields = element.custom.merge.match(/{{(.*?)}}/);
            }

            if (elementMergeFields?.length === 2) {
              element.set({
                text: '' + replacedMergeFields[elementMergeFields![0]],
                custom: {
                  merge: elementMergeFields![0],
                },
              });
            }
          }

          if (element.type === 'image') {
            property.images.forEach((image: any, index: number) => {
              if (element?.custom?.merge === `{{property.images.${(index + 1)}}}`) {
                element.set({
                  src: `${image.url}?designly=1`,
                });
              }
            });
          }
        });
      }
    });

    props.onHide();
  };

  const replaceMergeFields = (property: Property) => {
    const data = {} as any;

    mergeFields.forEach((mergeField: MergeField) => {
      const keyValue = property[mergeField.reference as keyof Property] as string;
      const value = keyValue ? mergeField.merge_field_output.replace(mergeField.merge_field, keyValue) : mergeField.merge_field;
      data[mergeField.merge_field] = value;
    });

    return data;
  };

  // Presentational
  const getPropertySearchContent = useMemo(() => {
    const onPropertySelect = (property: Property, isSelected: boolean) => {
      const newSelected = _.cloneDeep(selectedProperties);
      if (!isSelected) {
        newSelected.push(property);
      } else {
        _.remove(newSelected, (p) => p.id === property.id);
      }
      setSelectedProperties(newSelected);
    };

    const isPropertySelected = (property: Property) => !!_.find(selectedProperties, (p) => p.id === property.id);

    const PropertyItem = ({
      property,
      onPropertySelect,
    }: {
      property: Property;
      onPropertySelect: (property: Property, isSelected: boolean) => void;
    }): JSX.Element => {
      const { displayaddress, images, beds, baths, carports, listingdate, price } = property;
      const isSelected = isPropertySelected(property);
      const propertyAddress = displayaddress || getAddressString(property);

      const { src } = useImage({
        srcList: [images[0]?.url, `${REACT_APP_S3_BUCKET}${images[0]?.thumbnail || ''}`, placeholderImage],
        useSuspense: false,
      });

      return (
        <button
          onClick={() => onPropertySelect(property, isSelected)}
          className={`${styles.propertyItemContainer} ${isSelected ? styles.propertyItemSelected : ''}`}
        >
          <div className={styles.propertyItemRow}>
            <p className={styles.propertySpecs}>{propertyAddress}</p>
            {/* <Suspense fallback={<img src={placeholderImage} className={styles.propertyItemThumbnail} alt={propertyAddress} />}> */}
            <div className={styles.propertyItemThumbnailContainer}>
              <img src={src} alt={propertyAddress} />
            </div>
            {/* </Suspense> */}
          </div>
          <div className={styles.propertyItemRow}>
            <div className={styles.propertySpecs}>
              <div className={styles.propertySpecsRow}>
                <p>
                  Bed <strong>{beds}</strong>
                </p>
                <p>
                  Bath <strong>{baths}</strong>
                </p>
                <p>
                  Car <strong>{carports}</strong>
                </p>
              </div>
              <div className={styles.saleDateBg}>{listingdate}</div>
            </div>
            <div className={styles.propertySpecs}>
              <div className={styles.propertyPriceRow}>
                <div className={styles.priceHiddenIcon}>
                  <EyeOff color="#a4a4a7" />
                </div>
                <div className="price">{price}</div>
              </div>
            </div>
          </div>
        </button>
      );
    };

    if (isLoading || isError) {
      return (
        <div className={styles.loader}>
          <Bars ariaLabel="loading-indicator" color="#ACC9D8" wrapperClass={styles.bars} />
        </div>
      );
    }
    return (
      <div className={styles.propertySearchPropertyList}>
        {propertyList.map((property: Property, index: number) => (
          <PropertyItem key={index} property={property} onPropertySelect={onPropertySelect} />
        ))}
      </div>
    );
  }, [isLoading, isError, propertyList, selectedProperties]);

  return (
    <Modal {...props} contentClassName="p-0">
      <Modal.Header closeButton className="py-2 px-4">
        <div className={styles.headerTitleIcon}>
          <Sliders color="#344651" />
        </div>
        <Modal.Title>Property Search</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className={styles.propertySearchContent}>
          <div className={styles.propertySearchSidebarContainer}>
            <PropertySearchSidebar onSubmit={onSubmit} control={control} onInsertProperties={onInsertProperties} />
          </div>
          <div className={styles.propertySearchMainContent}>{getPropertySearchContent}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
