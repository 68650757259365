import { TailSpin } from "react-loader-spinner";
import './styles.scss';

interface OverlayLoaderProps {
    isLoading: boolean;
}

export const OverlayLoader = ({ isLoading }: OverlayLoaderProps): JSX.Element => {

    return <>
        {isLoading &&
            <div className='loading-overlay'>
                <div className='loading-overlay__inner'>
                    <TailSpin color="#0155A4" height={40} width={40} />
                </div>
            </div>
        }
    </>

}