import React, { useMemo, useState } from 'react';
import { StoreType } from 'polotno/model/store';
import './styles.scss';
import { LinesAndShapes } from './LinesAndShapes';
import { Icons } from './Icons';
import { TabItem, Tabs } from '../../_common/Tabs';

interface ShapeProps {
  store: StoreType;
}

enum MENU_ITEMS {
  SHAPES = 'shapes',
  ICONS = 'icons',
}

export const Shapes = ({ store }: ShapeProps): JSX.Element => {
  const [selectedMenu, setSelectedMenu] = useState<MENU_ITEMS>(MENU_ITEMS.SHAPES);

  const handleSelectMenu = (item: string) => {
    setSelectedMenu(item as MENU_ITEMS);
  };

  const tabItems = useMemo(
    (): TabItem[] => [
      {
        title: 'SHAPES',
        code: MENU_ITEMS.SHAPES,
      },
      // {
      //   title: 'ICONS',
      //   code: MENU_ITEMS.ICONS,
      // },
    ],
    [],
  );

  const Content = useMemo(() => {
    switch (selectedMenu) {
      case 'shapes':
        return <LinesAndShapes store={store} />;
      case 'icons':
        return <Icons store={store} />;
    }
  }, [selectedMenu, store]);

  return (
    <div className="shapes-content">
      <Tabs setSelectedMenu={handleSelectMenu} selectedMenu={selectedMenu} items={tabItems} />
      <div className="shapes-tab-content">{Content}</div>
    </div>
  );
};
