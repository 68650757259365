import { StoreType } from 'polotno/model/store';
import { unstable_setForceTextFit } from 'polotno/config';
import { useState, useRef } from 'react';
import { ChevronLeft, Download, File, Home, Lock, Save } from 'react-feather';
import { toast } from 'react-toastify';
import { useMenuState } from '@szhsin/react-menu';
import { parseSVG } from '../../utils/svg-parser';

import { API } from '../../api';
import { EntityTypes } from '../../constants/common';
import { Button } from '../_common/Button';
import { Menu, MenuOption } from '../_common/Menu';
import './styles.scss';
import { PropertySearchModal } from '../PropertySearch/PropertySearch';

import { REACT_APP_HOME_URL } from '../../constants/api';

import {observer} from 'mobx-react-lite';

import { SaveToCategoryModal } from '../Template/SaveToCategoryModal';
import DuplicateModal from '../Template/DuplicateModal';


// Mock Data
const getMergeField = (field: string) => {
  // TODO: API fetch
  switch (field) {
    case '{{prop.heading}}':
      return 'A spectacular ground floor balcony apartment';
    case '{{prop.description}}':
      return 'Occupying the ground floor of this Bath stone fronted ornate Grade II listed property this is a charming first floor property beautifully presented. There is stripped polished flooring throughout, high ceilings with original dressed ceiling cornices and full floor to ceiling original windows and fan lights to the balcony to the front. The reception has a feature fireplace with slate hearth and a double set of doors to the balcony. The kitchen is to the rear, a stylish modern contemporary kitchen fitted with a range of units with complimenting Corian work tops, inset sink, integrated dishwasher, fridge, induction hob and Bosch oven with overhead extractor. Attractive stained glass feature window.';
    case '{{prop.address}}':
      return 'Richmond Terrace, Clifton, Bristol';
    case '{{prop.price}}':
      return 'Fixed Price  £800,000';
    case '{{prop.type}}':
      return 'Apartment';
    case '{{prop.id}}':
      return '123456';
    case '{{prop.status}}':
      return 'For Sale';
    case '{{office.name}}':
      return 'Demo Real Estate';
    case '{{office.website}}':
      return 'www.vaultea.co.uk';
    case '{{office.phone}}':
      return '0330 333 4122';
  }

  return field;
};

const getImages = () => {
  return [
    'https://s3.ap-southeast-2.amazonaws.com/stage-html-md3.wbclients.com/resources/test-pro/photo-1.jpeg',
    'https://s3.ap-southeast-2.amazonaws.com/stage-html-md3.wbclients.com/resources/test-pro/photo-2.jpeg',
    'https://s3.ap-southeast-2.amazonaws.com/stage-html-md3.wbclients.com/resources/test-pro/photo-3.jpeg',
    'https://s3.ap-southeast-2.amazonaws.com/stage-html-md3.wbclients.com/resources/test-pro/photo-4.jpeg',
  ];
};

interface HeaderProps {
  store: StoreType;
  design: {
    id: string | undefined;
    type: string | undefined;
    dpi: number;
  };
  entityData: any;
  onShowGrid: () => void
}

enum HeaderActions {
  SAVE = 'save',
  INSERT_PROPERTY = 'insertProperty',
  DOWNLOAD_PDF = 'downloadPDF',
  DOWNLOAD_IMAGE = 'downloadImage',
  MENU_CLOSE = 'menuClose',
  SHOW_RULER = 'showRuler',
  SHOW_BLEED = 'showBleed',
  COMING_SOON = 'comingSoon',
  SAVE_TO_CATEGORY = 'saveToCategory',
  DUPLICATE = 'duplicate',
  SHOW_GRID = 'showGrid'
  // TODO: Misc. functionalities
}

const Header = (props: HeaderProps) => {
  // state
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSearchProperty, setShowSearchPropperty] = useState<boolean>(false);
  const [showSaveToCagetory, setShowSaveToCagetory] = useState<boolean>(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false);
  const [fileMenuProps, toggleFileMenu] = useMenuState({ transition: false });

  const fileMenuBtnRef = useRef(null);
  

  const [downloadMenuProps, toggleDownloadMenu] = useMenuState({ transition: false });
  const downloadMenuBtnRef = useRef(null);
  const save = (id: string | undefined, type: string | undefined, content: object) => {
    // toast.success('Saving...', {
    //   position: 'top-right',
    //   autoClose: 2000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });

    // if (type === EntityTypes.TEMPLATE) {
    //   setShowSaveTemplate(true);
    //   return;
    // }

    setIsSaving(true);
    let url = `/designs/${type}s/${id}`;
    if (type === EntityTypes.DESIGN) url = `/designs/${id}`;
    API.put(url, {
      content: JSON.stringify(content),
    })
      .then(() => {
        setIsSaving(false);
        toast.success('Saved', {
          hideProgressBar: true,
        });
      })
      .catch(() => {
        setIsSaving(false);
        toast.error('Error occurred. Please try again later.', {
          hideProgressBar: true,
        });
      });
  };

  const insertProperty = () => {
    // const elements = props?.store?.activePage?.children;

    // if (elements?.length) {
    //   let currentImageIndex = 0;

    //   elements?.forEach((element) => {
    //     if (element.type === 'text') {
    //       let mergeFields = String(element.text).match(/{{(.*?)}}/);

    //       if ((!mergeFields?.length || (mergeFields?.length && mergeFields?.length < 2)) && element?.custom?.merge) {
    //         mergeFields = element.custom.merge.match(/{{(.*?)}}/);
    //       }

    //       if (mergeFields?.length === 2) {
    //         element.set({
    //           text: getMergeField(mergeFields[0]),
    //           custom: {
    //             merge: mergeFields[0],
    //           },
    //         });
    //       }
    //     }

    //     if (element.type === 'image') {
    //       const propertyImages = getImages();

    //       element.set({
    //         src: propertyImages[currentImageIndex],
    //       });

    //       propertyImages.splice(currentImageIndex, 1);
    //       currentImageIndex++;
    //     }
    //   });
    // }

    setShowSearchPropperty(true);
  };

  const closeSearch = () => {
    setShowSearchPropperty(false);
  };

  const handleActions = async (action: HeaderActions) => {
    switch (action) {
      case HeaderActions.SAVE: {
        save(props.design.id, props.design.type, props.store.toJSON());
        toggleFileMenu(false);
        break;
      }
      case HeaderActions.INSERT_PROPERTY: {
        insertProperty();
        toggleFileMenu(false);
        break;
      }
      case HeaderActions.DOWNLOAD_IMAGE: {
        props.store.saveAsImage({ fileName: 'dsgnly-pro' });
        toggleFileMenu(false);
        break;
      }
      case HeaderActions.DOWNLOAD_PDF: {
        setIsLoading(true);
        await props.store.saveAsPDF({ dpi: props.design.dpi, fileName: 'dsgnly-pro', includeBleed: true});
        setIsLoading(false);
        toggleFileMenu(false);
        break;
      }
      case HeaderActions.MENU_CLOSE: {
        toggleFileMenu(false);
        break;
      }
      case HeaderActions.COMING_SOON: {
        toast.info('Coming soon!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        toggleFileMenu(false);
        break;
      }
      case HeaderActions.SHOW_RULER: {
        props.store.toggleRulers();
        break;
      }
      case HeaderActions.SHOW_BLEED: {
        props.store?.activePage?.set({
          bleed: 45
        });
        props.store.toggleBleed();
        break;
      }
      case HeaderActions.SAVE_TO_CATEGORY: {
        setShowSaveToCagetory(true);
        break;
      }
      case HeaderActions.DUPLICATE: {
        setShowDuplicateModal(true);
        break;
      }
      case HeaderActions.SHOW_GRID: {
        props.onShowGrid();
        break;
      }
    }
  };

  const handleFileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    toggleFileMenu(true);
  };

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target;

    if (!input?.files?.length) {
      return;
    }

    const reader = new FileReader();
    reader.onloadend = async function () {
      const text = reader.result as string;
      const json = await parseSVG(text);
      unstable_setForceTextFit(true);
      props.store.loadJSON(json, true);
      await props.store.waitLoading();
      unstable_setForceTextFit(false);
      input.value = '';

      props.store.setSize(props.entityData?.type?.width, props.entityData?.type?.height, true);
    };

    reader.readAsText(input.files[0]);
  };

  const downloadMenuOptions: MenuOption[] = [
    {
      title: 'Download as PDF',
      action: () => handleActions(HeaderActions.DOWNLOAD_PDF),
    },
    {
      title: 'Download as PNG',
      action: () => handleActions(HeaderActions.DOWNLOAD_IMAGE),
    },
  ];


  const fileMenuOptions: MenuOption[] = [
    // {
    //   title: 'Use auto-save',
    //   action: () => handleActions(HeaderActions.COMING_SOON),
    // },
    // {
    //   title: 'Save as template',
    //   action: () => handleActions(HeaderActions.COMING_SOON),
    // },
    {
      title: 'Move to category',
      action: () => handleActions(HeaderActions.SAVE_TO_CATEGORY),
    },
    // {
    //   title: 'Move to folder',
    //   action: () => handleActions(HeaderActions.COMING_SOON),
    // },
    {
      title: 'Duplicate',
      action: () => handleActions(HeaderActions.DUPLICATE),
      withDivider: true,
    },
    // {
    //   title: 'Lock Page',
    //   action: () => handleActions(HeaderActions.COMING_SOON),
    // },
    // {
    //   title: 'Show grid',
    //   action: () => handleActions(HeaderActions.SHOW_GRID),
    // },
    {
      title: 'Show ruler',
      action: () => handleActions(HeaderActions.SHOW_RULER),
    },
    // {
    //   title: 'Snap to grid',
    //   action: () => handleActions(HeaderActions.COMING_SOON),
    // },
    {
      title: 'Set bleed and crop area',
      action: () => handleActions(HeaderActions.SHOW_BLEED),
      withDivider: true,
    },
    {
      title: 'Import SVG',
      action: () => {
        const btnImportSvg = document.querySelector('#import-svg') as HTMLInputElement;
        btnImportSvg.click();
      },
    },
  ];

  const getFileMenuOptions = () : MenuOption[] => {

    const type = props.design.type;

    switch(type) {
      case EntityTypes.DESIGN: {
        return fileMenuOptions.filter(opt => !["Move to category", "Duplicate"].includes(opt.title));
      }
      case EntityTypes.TEMPLATE: {
        return fileMenuOptions.filter(opt => opt.title !== "Save as template");
      }
      default: {
        return fileMenuOptions;
      }
    }    

  }

  return (
    <div className="header">
      <SaveToCategoryModal
        id={props.design.id!}
        show={showSaveToCagetory}
        onClose={() => {
          setShowSaveToCagetory(false);
        }}
        reference={props.entityData?.type.reference}
      />
      <DuplicateModal
        show={showDuplicateModal}
        onClose={() => {
          setShowDuplicateModal(false);
        }}
        id={props.design.id!}
        reference={props.entityData?.type.reference}
      />
      <div className="header__buttoncol">
        <Button title="Home" type="empty" icon={<ChevronLeft color="#223646" />} onClick={() => window.location.href = REACT_APP_HOME_URL! + "/myprodesigns"} />
        <Button ref={fileMenuBtnRef} title="File" type="empty" icon={<File color="#223646" />} onClick={handleFileClick} />
        <Button title="Save" type="empty" icon={<Save color="#223646" />} onClick={() => handleActions(HeaderActions.SAVE)} />
        {/* <div className="header-file" onMouseEnter={handleHelpClick}>
          <div className="header-file--icon">
            <Save color="#223646" />
          </div>
          <h2 className="header-file--text">File</h2>
        </div> */}
        {isSaving && <h2 className="header__saving-indicator">Saving...</h2>}
      </div>
      <div className="header__buttoncol">
        {/* TODO: Template title */}
        <Button
          title="Insert a Property"
          type="empty"
          icon={<Home color="#223646" />}
          onClick={() => handleActions(HeaderActions.INSERT_PROPERTY)}
        />
        {/* <Button title="Download Image" type="empty" onClick={() => handleActions(HeaderActions.DOWNLOAD_IMAGE)} /> */}
        <Button
          ref={downloadMenuBtnRef}
          title="Download"
          icon={<Download color="white" size={25} />}
          type="primary"
          isLoading={isLoading}
          onClick={() => { toggleDownloadMenu(true) }}
        />
      </div>
      <Menu
        anchor={fileMenuBtnRef}
        options={getFileMenuOptions()}
        onClose={() => handleActions(HeaderActions.MENU_CLOSE)}
        {...fileMenuProps}
      />
      <Menu
        anchor={downloadMenuBtnRef}
        options={downloadMenuOptions}
        onClose={() => { toggleDownloadMenu(false) }}
        {...downloadMenuProps}
      />
      <input id="import-svg" type="file" accept="image/svg+xml" style={{ display: 'none' }} onChange={handleUpload} />
      <PropertySearchModal
        fullscreen
        show={showSearchProperty}
        onHide={closeSearch}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        store={props.store}
      />
    </div>
  );
};

export default observer(Header);
