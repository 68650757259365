import React, { ChangeEvent, useRef, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { partition } from 'lodash';
import { StoreType } from 'polotno/model/store';
import { ArrowLeft, ChevronDown, ChevronUp, Folder, Plus, Trash2, Upload } from 'react-feather';
import { Bars } from 'react-loader-spinner';
import { toast } from 'react-toastify';

import { REACT_APP_S3_BUCKET } from '../../../../constants/api';
import { AssetTypes } from '../../../../constants/common';
import { createFolder, deleteImage, uploadImage, useGetFiles, useGetFolders } from '../../../../hooks/files-folders-hooks';
import { Button } from '../../../_common/Button';
import { Dialog } from '../../../_common/Dialog';
import './styles.scss';

type ImageBrowserProps = {
  store: StoreType;
  type: AssetTypes;
};

type FilesFolder = {
  name: string;
  uuid: string;
};

export const ImageBrowser = ({ store, type }: ImageBrowserProps): JSX.Element => {
  // Local states
  const [showFolders, setShowFolders] = useState<boolean>(true);
  const [showImages, setShowImages] = useState<boolean>(true);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [deleteDialogSelect, setDeleteDialogSelect] = useState<string>('');
  const [overrideDialogSelect, setOverrideDialogSelect] = useState<any>(null);
  const [folderDepth, setFolderDepth] = useState<FilesFolder[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const folderDepthLength = folderDepth.length;
  const currentFolderId = folderDepthLength > 0 ? folderDepth[folderDepthLength - 1].uuid : '';

  // Query hooks
  const foldersQuery = useGetFolders(type, currentFolderId);
  // TODO: Infinite query
  const filesQuery = useGetFiles(type, currentFolderId);
  const isLoading = foldersQuery.isLoading || filesQuery.isLoading;
  const isError = foldersQuery.isError || filesQuery.isError;

  // Callbacks and Operations
  const addImage = (image: any) => () => {
    store.activePage?.addElement({
      type: 'image',
      src: `${REACT_APP_S3_BUCKET}/${image.path}`,
      x: 0,
      y: 0,
      keepRatio: false,
    });
  };

  const handleNavigateForward = (folder: any) => () => {
    // currying
    const newFolderDepth = [...folderDepth];
    newFolderDepth.push(folder);
    setFolderDepth(newFolderDepth);
  };

  const handleNavigatePrevious = (index: number) => () => {
    if (index === 0) {
      setFolderDepth([]);
    } else {
      const newFolderDepth = folderDepth.slice(0, index);
      setFolderDepth(newFolderDepth);
    }
  };

  const handleUploadFile = async (file: any, override?: boolean) => {
    console.log('[Log] reaches method', file);
    try {
      // TODO: Fix re-uploading issue
      await uploadImage(type, file, override || false, folderDepthLength > 0 ? folderDepth[folderDepthLength - 1].uuid : '');
      filesQuery.refetch();
      toast.success('Upload success.', {
        hideProgressBar: true,
      });
    } catch (e: any) {
      if (e.response?.data?.status === 'FILE_EXISTS') {
        handleOverrideDialog('open', file)();
      } else {
        toast.error('Error uploading file. Please try again later.', {
          hideProgressBar: true,
        });
        console.log(e);
      }
    }
  };

  const handleOnChangeFile = async (e: ChangeEvent<HTMLInputElement>, override?: boolean) => {
    // TODO: Create uploading status/placeholder/loader
    // TODO: Support multi-file and folder upload
    console.log('[Log] reaches func', { e });
    if (e.target?.files && e.target?.files?.length > 0) {
      console.log('[Log] reaches cond');
      handleUploadFile(e.target.files[0], false);
    }
  };

  const handleSelectImage = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleDeleteImage = async (uuid: any) => {
    try {
      await deleteImage(uuid);
      filesQuery.refetch();
      toast.success('Successfully deleted image.', {
        hideProgressBar: true,
      });
    } catch (e) {
      console.log('Error', { e });
      toast.error('Error occurred. Please try again later.', {
        hideProgressBar: true,
      });
    }
  };

  const handleOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
  };

  const handleCreateDialog = async (name: string) => {
    if (name && name !== '') {
      try {
        setOpenCreateDialog(false);
        const res = await createFolder(name, type, folderDepthLength > 0 ? folderDepth[folderDepthLength - 1].uuid : '');
        if (res) {
          foldersQuery.refetch();
          toast.success('Folder created.', {
            hideProgressBar: true,
          });
        }
      } catch (e) {
        toast.error('Error creating folder. Please try again later.', {
          hideProgressBar: true,
        });
        console.log(e);
      }
    }
  };

  // Delete dialog funcs.
  const handleModalAction = (action: 'close' | 'confirm' | 'cancel' | 'open', imageId?: string) => async () => {
    switch (action) {
      case 'open':
        if (imageId) setDeleteDialogSelect(imageId);
        break;
      case 'close':
      case 'cancel':
        setDeleteDialogSelect('');
        break;
      case 'confirm': {
        if (deleteDialogSelect) await handleDeleteImage(deleteDialogSelect);
        setDeleteDialogSelect('');
      }
    }
  };

  // Override dialog funcs.
  const handleOverrideDialog = (action: 'close' | 'confirm' | 'open', obj?: any) => async () => {
    switch (action) {
      case 'open':
        if (obj) setOverrideDialogSelect(obj);
        break;
      case 'close':
        setOverrideDialogSelect(null);
        break;
      case 'confirm': {
        setOverrideDialogSelect(null);
        if (overrideDialogSelect) await handleUploadFile(overrideDialogSelect, true);
      }
    }
  };

  // Presentational functions
  const getBreadcrumbs = () => {
    let homeName;
    switch (type) {
      case AssetTypes.USER: {
        homeName = 'My Images';
        break;
      }
      case AssetTypes.CLIENT: {
        homeName = 'Office';
        break;
      }
      case AssetTypes.GROUP: {
        homeName = 'Group';
        break;
      }
    }

    return (
      <Breadcrumb aria-label="breadcrumb" className="breadcrumbWrapper">
        <Breadcrumb.Item as="button" className="breadcrumb-custom-item breadcrumb-item--text" onClick={handleNavigatePrevious(0)}>
          {homeName}
        </Breadcrumb.Item>
        {folderDepthLength > 0 &&
          folderDepth.map((i: any, index) => (
            <Breadcrumb.Item as="button" className="breadcrumb-custom-item breadcrumb-item--text" onClick={handleNavigatePrevious(index + 1)}>
              {i.name}
            </Breadcrumb.Item>
          ))}
      </Breadcrumb>
    );
  };

  const getFolders = () => {
    if (isLoading || isError) {
      return (
        <div className="loader">
          <Bars color="#acc9d8" />
        </div>
      );
    }
    return (
      <div className="folders">
        {folderDepthLength > 0 && (
          <button className="folders__item" key={`folder-goback`} onClick={handleNavigatePrevious(folderDepthLength - 1)}>
            <div className="folders__item--icon">
              <ArrowLeft color="#acc9d8" size={21} />
            </div>
            <div className="folders__item--text">Go Back</div>
          </button>
        )}
        {foldersQuery.data?.data?.map((folder: any, index: number) => (
          <button className="folders__item" key={`folder-${index}`} onClick={handleNavigateForward(folder)}>
            <div className="folders__item--icon">
              <Folder color="#acc9d8" size={21} />
            </div>
            <div className="folders__item--text">{folder.name}</div>
          </button>
        ))}
        <button className="folders__item" key={`folder-addnew`} onClick={handleOpenCreateDialog}>
          <div className="folders__item--icon">
            <Plus color="#acc9d8" size={21} />
          </div>
          <div className="folders__item--text">New</div>
        </button>
      </div>
    );
  };

  const getImages = () => {
    if (isLoading || isError) {
      return (
        <div className="loader">
          <Bars color="#acc9d8" />
        </div>
      );
    }

    // TODO: Type all temporary any
    let imageList: any = [];

    if (filesQuery?.data?.data && filesQuery.data.data.length > 0) {
      // Columnize combo - split array into two but maintain order
      const withIndex = (fn: Function) => {
        let index = 0;
        return (thing: any) => fn(thing, index++);
      };
      imageList = partition(
        filesQuery.data.data,
        withIndex((_thing: any, idx: number) => !(idx % 2)),
      );
    }

    return (
      <div className="images-section">
        <div className="imagecolumns">
          {imageList.map((imageGroup: any, index: number) => {
            return (
              <div className="imagecolumns__column" key={`imagecolumn-${index}`}>
                {imageGroup.map((image: any, index2: number) => {
                  return (
                    <div key={`column-${index}-img-${index2}`} className="img-item" style={{ position: 'relative' }}>
                      <img
                        alt="Stock"
                        className="img-item--image"
                        src={`${REACT_APP_S3_BUCKET}/${image.thumbnail}`}
                        onClick={addImage(image)}
                      />
                      <button className="img-item--delete" onClick={handleModalAction('open', image.uuid)}>
                        <Trash2 color="white" size={18} />
                      </button>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const getDeleteDialog = () => {
    return (
        <Dialog
          title="Confirm Delete"
          type="prompt"
          message="Are you sure you want to delete this item?"
          submitText="Delete"
          closeText="Cancel"
          open={!!deleteDialogSelect}
          onClose={handleModalAction('close')}
          onSubmit={handleModalAction('confirm')}
        />
    );
  };

  const getOverrideDialog = () => {
    return (
        <Dialog
          title="Overwrite file?"
          type="prompt"
          message="File already exists. Overwrite?"
          submitText="Overwrite"
          closeText="Cancel"
          open={!!overrideDialogSelect}
          onClose={handleOverrideDialog('close')}
          onSubmit={handleOverrideDialog('confirm')}
        />
    );
  };

  return (
    <div className="user-images">
      <div className="section">
        {getBreadcrumbs()}
        <input
          type="file"
          accept="image/png, image/jpeg"
          multiple={false}
          ref={inputRef}
          onChange={handleOnChangeFile}
          onClick={() => {
            if (inputRef.current?.value) inputRef.current.value = '';
          }}
          className="hiddeninput"
        />
        <Button
          title="Upload Image"
          icon={<Upload color="white" size={25} />}
          type="primary"
          textClassName="dl-button--text"
          containerClassName="dl-button"
          onClick={handleSelectImage}
        />
      </div>
      <div className="section">
        <button className="section__header" onClick={() => setShowFolders(!showFolders)}>
          <h5 className="section__header--text">FOLDERS</h5>
          <div className="section__header--toggle">
            {!showFolders ? <ChevronDown color="#acc9d8" size="20" /> : <ChevronUp color="#acc9d8" size="20" />}
          </div>
        </button>
        {showFolders && getFolders()}
      </div>
      <div className="section">
        <button className="section__header" onClick={() => setShowImages(!showImages)}>
          <h5 className="section__header--text">IMAGES</h5>
          <div className="section__header--toggle">
            {!showImages ? <ChevronDown color="#acc9d8" size="20" /> : <ChevronUp color="#acc9d8" size="20" />}
          </div>
        </button>
        {showImages && getImages()}
      </div>
      <Dialog
        title="Create new folder"
        placeholder="Folder name"
        type="input"
        open={openCreateDialog}
        onClose={handleCloseCreateDialog}
        onSubmit={handleCreateDialog}
      />
      {getDeleteDialog()}
      {getOverrideDialog()}
    </div>
  );
};
