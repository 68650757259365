import { AxiosInstance, AxiosPromise } from 'axios';
import { REACT_APP_ENDPOINT } from '../constants/api';
import apiInterceptor from './interceptors';

const axios = require('axios').default;

// API Instance
export const instance: AxiosInstance = axios.create({
  baseURL: REACT_APP_ENDPOINT,
  timeout: 30 * 1000,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
});

// API Interceptors
apiInterceptor(instance);

// API Methods
export const API = {
  post(endpoint: string, formData: any): AxiosPromise {
    return instance.post(endpoint, formData);
  },

  put(endpoint: string, formData: any): AxiosPromise {
    return instance.put(endpoint, formData);
  },

  get(endpoint: string): AxiosPromise {
    return instance.get(endpoint);
  },

  delete(endpoint: string): AxiosPromise {
    return instance.delete(endpoint);
  },
};
