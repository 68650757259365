export enum CLASSIFICATION_TYPES {
  RESIDENTIAL = 'residential',
  COMMERCIAL = 'commercial',
  BUSINESS = 'business',
  RURAL = 'rural',
  LAND = 'land',
  HOLIDAY_RENTAL = 'holidayRental',
  LIVESTOCK = 'livestock',
  CLEARING_SALES = 'clearingSales',
}

export enum STATUS_TYPES {
  LISTING = 'listing',
  RENTAL = 'rental',
  AUCTION = 'auction',
  SOLD = 'sold',
  LEASED = 'leased',
  LISTING_OR_CONDITIONAL = 'listingOrConditional',
  CONDITIONAL = 'conditional',
  UNCONDITIONAL = 'unconditional',
  PROSPECT = 'prospect',
  APPRAISAL = 'appraisal',
}

export enum ORDER_BY {
  MODIFIED_DATE_DESC = 'modifiedDateDesc',
  MODIFIED_DATE_ASC = 'modifiedDateAsc',
  INSERTED_DATE_DESC = 'insertedDateDesc',
  INSERTED_DATE_ASC = 'insertedDateAsc',
}

export type SearchFilters = {
  classification: CLASSIFICATION_TYPES;
  status: STATUS_TYPES;
  orderBy: ORDER_BY;
};

export const defaultSearchFilters: SearchFilters = {
  classification: CLASSIFICATION_TYPES.RESIDENTIAL,
  status: STATUS_TYPES.LISTING,
  orderBy: ORDER_BY.MODIFIED_DATE_DESC,
};
