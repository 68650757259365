import { Menu } from '@szhsin/react-menu';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Lock } from 'react-feather';
import { Button } from '../../_common/Button';
import './styles.scss';
import { observer } from 'mobx-react-lite';
import { StoreType } from 'polotno/model/store';
import { getCurrentUser } from '../../../utils/user-utils';

enum LockType {
  MOVE = 'move',
  STYLE = 'style',
  CONTENT = 'content',
}

interface IProps {
  store: StoreType;  
  entityData: any;
}

const LockMenu = ({ store, entityData }: IProps) => {

  const [elementStates, setElementStates] = useState({ canMove: false, canStyle: false, canModifyContent: false });

  const { is_admin } = getCurrentUser();
  const { is_owner } = entityData;

  useEffect(() => {
    if (store.selectedElements.length) {
      const el = store.selectedElements[0];
      setElementStates({
        canMove: !el.draggable,
        canStyle: !el.styleEditable,
        canModifyContent: !el.contentEditable,
      });
    }
  }, [store.selectedElements]);

  const handleCheckboxChange = (data: any, type: LockType) => {
    const isChecked = data.target.checked;

    store.selectedElements.forEach((el) => {
        switch (type) {
          case LockType.MOVE: {
            el.set({
              draggable: !isChecked,
            });
            break;
          }
          case LockType.CONTENT: {
            el.set({
              contentEditable: !isChecked,
            });
            break;
          }
          case LockType.STYLE: {
            el.set({
              styleEditable: !isChecked,
            });
            break;
          }
        }
      });

      switch (type) {
        case LockType.MOVE: {
          setElementStates({
            ...elementStates,
            canMove: isChecked as boolean,
          });
          break;
        }
        case LockType.CONTENT: {
          setElementStates({
            ...elementStates,
            canModifyContent: isChecked as boolean,
          });
          break;
        }
        case LockType.STYLE: {
          setElementStates({
            ...elementStates,
            canStyle: isChecked as boolean,
          });
          break;
        }
      }
  }

  return (
    <div className="lockMenu">
      <Menu menuButton={<Button disabled={ (!is_admin && !is_owner) || store.selectedElements.length === 0 } title="Lock" type="empty" icon={<Lock color="#223646" />} />} transition>
        <div className="lockMenuItem">
          <span>Movement</span>
          <Form.Check checked={elementStates.canMove} type="switch" onChange={(e) => handleCheckboxChange(e, LockType.MOVE)} />
        </div>
        <div className="lockMenuItem">
          <span>Content</span>
          <Form.Check checked={elementStates.canModifyContent} type="switch" onChange={(e) => handleCheckboxChange(e, LockType.CONTENT)} />
        </div>
        <div className="lockMenuItem">
          <span>Style</span>
          <Form.Check checked={elementStates.canStyle} type="switch" onChange={(e) => handleCheckboxChange(e, LockType.STYLE)} />
        </div>
      </Menu>
    </div>
  );
};

export default observer(LockMenu);
