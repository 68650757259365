import { useQuery } from 'react-query';
import { API } from '../api';
import { API_ENDPOINTS } from '../constants/api';
import { EntityTypes } from '../constants/common';

const fetchStoreData = async (id: string, type: EntityTypes, designsTemplateId: string | null) => {
  let dataAPI = API_ENDPOINTS.TEMPLATES;
  switch (type) {
    case EntityTypes.ELEMENT:
      dataAPI = API_ENDPOINTS.ELEMENTS;
      break;
    case EntityTypes.DESIGN:
      dataAPI = API_ENDPOINTS.DESIGNS;
      break;
    case EntityTypes.TEMPLATE:
      dataAPI = API_ENDPOINTS.TEMPLATES;
      break;
  }

  var requestUrl = `${dataAPI}/${id}`;

  if(designsTemplateId) {
    requestUrl += `?designs_template_id=${designsTemplateId}`
  }

  const { data } = await API.get(requestUrl);
  return data;
};

export const useGetStoreData = (id: string, type: EntityTypes, designsTemplateId: string | null) => {
  return useQuery(['store-data', id, type], () => fetchStoreData(id, type, designsTemplateId));
};
