import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';
import { API } from '../api';
import { API_ENDPOINTS, REACT_APP_ENDPOINT } from '../constants/api';
import { AssetTypes } from '../constants/common';

const axios = require('axios').default;

// Custom axios instance, interceptor-free
const instance: AxiosInstance = axios.create({
  baseURL: REACT_APP_ENDPOINT,
  timeout: 30 * 1000,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
});

const fetchFolders = async (type: AssetTypes, parent: string) => {
  const parentParam = parent ? `&parent_files_folder_id=${parent}` : '';
  const { data } = await instance.get(`${API_ENDPOINTS.FOLDERS}/?type=${type}${parentParam}`);
  return data;
};

const fetchFiles = async (type: AssetTypes, parent: string) => {
  const parentParam = parent ? `&files_folder_id=${parent}` : '';
  const { data } = await instance.get(`${API_ENDPOINTS.FILES}/?type=${type}${parentParam}`);
  return data;
};

export const useGetFolders = (type: AssetTypes, parent: string) => {
  return useQuery(['folders', type, parent], () => fetchFolders(type, parent));
};

export const useGetFiles = (type: AssetTypes, parent: string) => {
  return useQuery(['filesfolder', type, parent], () => fetchFiles(type, parent));
};

export const createFolder = async (name: string, type: AssetTypes, parentId: string) => {
  return API.post(API_ENDPOINTS.FOLDERS, { name, type, parent_files_folder_id: parentId });
};

export const uploadImage = async (type: AssetTypes, file: any, override?: boolean, parent?: string) => {
  const formData = new FormData();
  formData.append('type', type);
  formData.append('file', file);
  if (override) {
    formData.append('overwrite', '1');
  }
  if (parent) formData.append('files_folder_id', parent);

  return await instance.post(API_ENDPOINTS.FILES, formData);
};

export const deleteImage = async (id: string) => {
  return API.delete(`${API_ENDPOINTS.FILES}/${id}`);
};
