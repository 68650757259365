import React from 'react';

import './styles.scss';

export const Landing = (): JSX.Element => {
  return (
    <div className="landing">
      <div className="landing__text">
        <h1>Designly</h1>
        <p>What will you create?</p>
      </div>
    </div>
  );
};
