import { StoreType } from 'polotno/model/store';
import { LayersSection } from 'polotno/side-panel/side-panel';
import React from 'react';
import './styles.scss';

interface LayersProps {
  store: StoreType;
}

export const Layers = ({ store }: LayersProps): JSX.Element => {
  return (
    <div className="layers-content">
      <h5 className="sidebar-header">LAYERS</h5>
      <LayersSection.Panel store={store} />
    </div>
  );
};
