import React from 'react';
import { Form } from 'react-bootstrap';
import { Search } from 'react-feather';
import { Control, Controller } from 'react-hook-form';

import { CLASSIFICATION_TYPES, ORDER_BY, SearchFilters, STATUS_TYPES } from '../../../constants/types/search';
import { Button } from '../../_common/Button';

import styles from './PropertySearchSidebar.module.scss';

type PropertySearchSidebarProps = {
  control: Control<SearchFilters>;
  onSubmit: () => void;
  onInsertProperties: () => void;
};

type FormItemProps = {
  name: 'classification' | 'status' | 'orderBy';
  control: Control<SearchFilters>;
  items: any[];
};

const PropertyClassItems = [
  {
    label: 'Residential',
    value: CLASSIFICATION_TYPES.RESIDENTIAL,
  },
  {
    label: 'Commercial',
    value: CLASSIFICATION_TYPES.COMMERCIAL,
  },
  {
    label: 'Business',
    value: CLASSIFICATION_TYPES.BUSINESS,
  },
  {
    label: 'Rural',
    value: CLASSIFICATION_TYPES.RURAL,
  },
  {
    label: 'Land',
    value: CLASSIFICATION_TYPES.LAND,
  },
  {
    label: 'Holiday Rental',
    value: CLASSIFICATION_TYPES.HOLIDAY_RENTAL,
  },
  {
    label: 'Livestock',
    value: CLASSIFICATION_TYPES.LIVESTOCK,
  },
  {
    label: 'Clearing Sales',
    value: CLASSIFICATION_TYPES.CLEARING_SALES,
  },
];

const PropertyStatusItems = [
  {
    label: 'Sales',
    value: STATUS_TYPES.LISTING,
  },
  {
    label: 'Lease',
    value: STATUS_TYPES.RENTAL,
  },
  {
    label: 'Auction',
    value: STATUS_TYPES.AUCTION,
  },
  {
    label: 'Sold',
    value: STATUS_TYPES.SOLD,
  },
  {
    label: 'Leased',
    value: STATUS_TYPES.LEASED,
  },
  {
    label: 'Listing / Conditional',
    value: STATUS_TYPES.LISTING_OR_CONDITIONAL,
  },
  {
    label: 'Under Offer / Conditional',
    value: STATUS_TYPES.CONDITIONAL,
  },
  {
    label: 'Unconditional',
    value: STATUS_TYPES.UNCONDITIONAL,
  },
  {
    label: 'Prospect',
    value: STATUS_TYPES.PROSPECT,
  },
  {
    label: 'Appraisal',
    value: STATUS_TYPES.APPRAISAL,
  },
];

const SortItems = [
  {
    label: 'Newest Modified',
    value: ORDER_BY.MODIFIED_DATE_DESC,
  },
  {
    label: 'Oldest Modified',
    value: ORDER_BY.MODIFIED_DATE_ASC,
  },
  {
    label: 'Newest Inserted',
    value: ORDER_BY.INSERTED_DATE_DESC,
  },
  {
    label: 'Oldest Inserted',
    value: ORDER_BY.INSERTED_DATE_ASC,
  },
];

export const PropertySearchSidebar = ({ onSubmit, control, onInsertProperties }: PropertySearchSidebarProps): JSX.Element => {
  const FormItem = ({ name, control, items }: FormItemProps): JSX.Element => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Form.Select {...field} className="mb-3">
          {items.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </Form.Select>
      )}
    />
  );

  return (
    <div className={styles.propertySearchSidebar}>
      <Form onSubmit={onSubmit}>
        {/* SEARCH GROUps */}
        <Button
          title="Search"
          containerClassName={styles.fullLengthButton}
          icon={<Search color="white" size={25} />}
          type="primary"
          htmlType="submit"
        />

        <Form.Group className="mb-3 mt-3" controlId="propertySearchFiltersGroup">
          {/* FILTERS */}
          <Form.Label>
            <strong>Property Class</strong>
          </Form.Label>
          <FormItem name="classification" control={control} items={PropertyClassItems} />
          <Form.Label>
            <strong>Based On</strong>
          </Form.Label>
          <FormItem name="status" control={control} items={PropertyStatusItems} />

          {/* <Form.Label>
            <strong>Filter by Bedrooms</strong>
          </Form.Label>
          <Form.Label>
            <strong>Filter by Property Type</strong>
          </Form.Label>
          */}

          {/* SORT */}
          <Form.Label>
            <strong>Sort by</strong>
          </Form.Label>
          <FormItem name="orderBy" control={control} items={SortItems} />

          {/* CHECKBOXES */}
          <Form.Check type="checkbox" id="internalMarketingOnly" label="Public listings only" />
          <Form.Check type="checkbox" id="multiOffice" label={`Include "Multi Office Listings"`} />
          <Form.Check type="checkbox" id="includeInternal" label="Include Marketing only" />
        </Form.Group>

        {/* ACTIONS */}
        <Button
          title="Insert Properties"
          containerClassName={styles.fullLengthButton}
          textClassName={styles.buttonText}
          onClick={() => onInsertProperties()}
          type="primary"
        />

        <Button
          title="Clear Results"
          containerClassName={styles.fullLengthButton}
          textClassName={styles.buttonText}
          type="secondary"
          htmlType="reset"
        />
      </Form>
    </div>
  );
};
