import React from 'react';
import classNames from 'classnames';

import './index.scss';

export type TabItem = {
  title: string;
  code: string;
};

type TabsProps = {
  items: TabItem[];
  setSelectedMenu: (menu: string) => void;
  selectedMenu: string;
};

const TabsComponent = ({ items, setSelectedMenu, selectedMenu }: TabsProps): JSX.Element => {
  return (
    <ul className="tabbar-tabs">
      {items.map((item, index) => (
        <li key={`tabbar-tab-${index}`} className={classNames('nav-item', { active: item.code === selectedMenu })}>
          <button className="nav-btn" onClick={() => setSelectedMenu(item.code)}>
            {item.title}
          </button>
        </li>
      ))}
    </ul>
  );
};

export const Tabs = React.memo(TabsComponent);
