import React, { Fragment, useMemo, useState, useRef } from 'react';
import classNames from 'classnames';
import { StoreType } from 'polotno/model/store';
import {
  ChevronLeft,
  Code,
  Grid,
  HelpCircle,
  Image,
  Layers,
  Layout,
  MessageSquare,
  Octagon,
  PlayCircle,
  Share2,
  Sliders,
  Type,
} from 'react-feather';
import { toast, ToastOptions } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { useMenuState } from '@szhsin/react-menu';

// Assets
import logo from '../../assets/logo.svg';
import { Elements } from './Elements';
import { Images } from './Images';
import { Layers as SidebarLayers } from './Layers';
import { MergeFields } from './MergeFields';
import { Shapes } from './Shapes';
import './styles.scss';

// Components
import { Menu, MenuOption } from '../_common/Menu';
import { Templates } from './Templates';
import { Text } from './Text';
import { EntityTypes } from '../../constants/common';
import { REACT_APP_HOME_URL } from '../../constants/api';

interface SidebarProps {
  store: StoreType;
  design: {
    id: string | undefined;
    type: EntityTypes;
  };
  typeId?: string;
  entityData: any;
}

enum MenuTypes {
  NONE = 'none',
  LAYERS = 'layers',
  TEMPLATES = 'templates',
  TEXT = 'text',
  SHAPES = 'shapes',
  IMAGES = 'images',
  ELEMENTS = 'elements',
  MERGE_FIELDS = 'mergeFields',
  SETTINGS = 'settings',
}

export const Sidebar = ({ store, design, typeId = '', entityData }: SidebarProps): JSX.Element => {
  const [menu, setMenu] = useState<MenuTypes | undefined>(MenuTypes.NONE);
  // Show/Hide sidebar on load
  const [showMenu, setShowMenu] = useState<boolean>(false);

  // Help Menu related stuff
  const [helpMenuProps, toggleHelpMenu] = useMenuState({ transition: false });
  const helpAnchorRef = useRef(null);

  const helpMenuOptions: MenuOption[] = [
    {
      title: 'Watch Training Videos',
      action: () => handleHelpAction('videos'),
      icon: <PlayCircle />,
      withDivider: true,
    },
    {
      title: `Read our FAQ's`,
      action: () => handleHelpAction('faq'),
      icon: <HelpCircle />,
      withDivider: true,
    },
    {
      title: 'Submit a support request',
      action: () => handleHelpAction('support'),
      icon: <MessageSquare />,
    },
  ];

  const getMenu = useMemo(() => {
    switch (menu) {
      case MenuTypes.NONE:
        return <Fragment />;
      case MenuTypes.LAYERS:
        return <SidebarLayers store={store} />;
      case MenuTypes.TEMPLATES:
        return <Templates store={store} typeId={typeId} />;
      case MenuTypes.TEXT:
        return <Text store={store} />;
      case MenuTypes.SHAPES:
        return <Shapes store={store} />;
      case MenuTypes.IMAGES:
        return <Images store={store} />;
      case MenuTypes.ELEMENTS:
        return <Elements store={store} design={design} />;
      case MenuTypes.MERGE_FIELDS:
        return <MergeFields store={store} entityData={entityData} />;
      default:
        return <Fragment />;
    }
  }, [design, menu, store, typeId]);

  const toggleMenu = (newMenu: MenuTypes) => {
    if (menu === newMenu) {
      setMenu(MenuTypes.NONE);
      setShowMenu(false);
    } else if (menu === MenuTypes.NONE) {
      setShowMenu(true);
      setMenu(newMenu);
    } else {
      setMenu(newMenu);
    }
  };

  const handleHelpAction = (action: 'close' | 'videos' | 'faq' | 'support') => {
    const toastProps: ToastOptions = {
      position: 'bottom-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };

    switch (action) {
      case 'close': {
        toggleHelpMenu(false);
        break;
      }
      case 'videos': {
        window.open(`${REACT_APP_HOME_URL}/help/training-videos`, "_blank");
        toggleHelpMenu(false);
        break;
      }
      case 'faq': {
        window.open(`${REACT_APP_HOME_URL}/help/faqs`, "_blank");
        toggleHelpMenu(false);
        break;
      }
      case 'support': {
        window.open(`${REACT_APP_HOME_URL}/help/support`, "_blank");
        toggleHelpMenu(false);
        break;
      }
    }
  };

  const handleHelpClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    toggleHelpMenu();
  };

  return (
    <div className="sidebar">
      <ReactTooltip effect="solid" />
      <div className="sidebar__menu-items">
        <img src={logo} alt="Logo" className="logo" />
        <div className="main-items">
          {design.type === EntityTypes.DESIGN && (
            <button
              type="button"
              data-tip="Templates"
              className={`nav-btn ${menu === MenuTypes.TEMPLATES ? 'active' : ''}`}
              onClick={() => toggleMenu(MenuTypes.TEMPLATES)}
            >
              <Layout size="30" />
            </button>
          )}
          <button
            type="button"
            data-tip="Lines & Shapes"
            className={`nav-btn ${menu === MenuTypes.SHAPES ? 'active' : ''}`}
            onClick={() => toggleMenu(MenuTypes.SHAPES)}
          >
            <Octagon size="30" />
          </button>
          <button
            type="button"
            data-tip="Images"
            className={`nav-btn ${menu === MenuTypes.IMAGES ? 'active' : ''}`}
            onClick={() => toggleMenu(MenuTypes.IMAGES)}
          >
            <Image size="30" />
          </button>
          <button
            type="button"
            data-tip="Text"
            className={`nav-btn ${menu === MenuTypes.TEXT ? 'active' : ''}`}
            onClick={() => toggleMenu(MenuTypes.TEXT)}
          >
            <Type size="30" />
          </button>
          <button
            type="button"
            data-tip="Elements"
            className={`nav-btn ${menu === MenuTypes.ELEMENTS ? 'active' : ''}`}
            onClick={() => toggleMenu(MenuTypes.ELEMENTS)}
          >
            <Grid size="30" />
          </button>
          <button
            type="button"
            data-tip="Merge Fields"
            className={`nav-btn ${menu === MenuTypes.MERGE_FIELDS ? 'active' : ''}`}
            onClick={() => toggleMenu(MenuTypes.MERGE_FIELDS)}
          >
            <Code size="30" />
          </button>
          <button
            type="button"
            data-tip="Layers"
            className={`nav-btn ${menu === MenuTypes.LAYERS ? 'active' : ''}`}
            onClick={() => toggleMenu(MenuTypes.LAYERS)}
          >
            <Layers size="30" />
          </button>
          {/* <button type="button" data-tip="Settings" className={`nav-btn ${menu === MenuTypes.SETTINGS ? 'active' : ''}`}>
            <Sliders size="30" />
          </button> */}
        </div>
        <div className="misc-items">
          {/* <button data-tip="Share" className="nav-btn">
            <Share2 size="30" />
          </button> */}
          <button ref={helpAnchorRef} data-tip="Help & Support" className="nav-btn" onClick={handleHelpClick}>
            <HelpCircle size="30" />
          </button>
        </div>
      </div>
      <div className={classNames('sidebar__menu-content', showMenu ? 'sidebarShown' : 'sidebarHidden')} id="scrollableSidebar">
        {getMenu}
        <button className="sidebar__menutoggle" onClick={() => setShowMenu(false)}>
          <ChevronLeft color="#acc9d8" />
        </button>
      </div>
      <Menu
        title="NEED HELP?"
        options={helpMenuOptions}
        onClose={() => handleHelpAction('close')}
        anchor={helpAnchorRef}
        {...helpMenuProps}
        direction="right"
        align="end"
        offsetX={4}
      />
    </div>
  );
};

export default Sidebar;
