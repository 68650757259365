import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
// import { ControlledMenu, MenuItem, SubMenu, useMenuState } from '@szhsin/react-menu';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Transition, TransitionStatus } from 'react-transition-group';
import Header from '../../../components/Header';
import { PageSidebar } from '../../../components/PageSidebar/PageSidebar';
import { OverlayLoader } from '../../../components/OverlayLoader';

// Polotno
import Workspace from 'polotno/canvas/workspace';
import { createStore } from 'polotno/model/store';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
// Editor Components
import Sidebar from '../../../components/Sidebar';
import { EntityTypes } from '../../../constants/common';
import { useGetStoreData } from '../../../hooks/page-hooks';
import './styles.scss';
import { cloneDeep, isEmpty } from 'lodash';
// import { Menu, MenuOption } from '../../../components/_common/Menu';


import { unstable_setTextVerticalResizeEnabled, unstable_setTextOverflow } from 'polotno/config';

import GridToolbar from '../../../components/GridToolbar';
import LockMenu from '../../../components/CustomToolbar/LockMenu';

unstable_setTextVerticalResizeEnabled(true);
unstable_setTextOverflow('ellipsis');

const store = createStore({
  key: process.env.REACT_APP_POLOTNO_PUBLIC_KEY ? process.env.REACT_APP_POLOTNO_PUBLIC_KEY : 'invalid',
  showCredit: false,
});

enum ActionType {
  CUT = 'cut',
  COPY = 'copy',
  PASTE = 'paste',
  DELETE = 'delete',
  MERGE_FIELD = 'MERGE_FIELD',
  TOGGLE_LOCK = 'toggleLock',
  ADD_TO_ELEMENT = 'addToElement',
  SHOW_RULER = 'showRuler',
  COMING_SOON = 'comingSoon', // TODO: Temp
}

export const DesignerScreen = (): JSX.Element => {
  // Props
  const { id = '', type = EntityTypes.TEMPLATE } = useParams<{ id: string; type: EntityTypes }>();
  const [searchParams] = useSearchParams();

  // States
  const [dpi, setDpi] = useState(72);
  const [reload, setReload] = useState<boolean>(false);

  const [isGridHidden, setIsGridHidden] = useState(true);

  // Hide/Show sidebar on load
  const [showPageSidebar, setShowPageSidebar] = useState<boolean>(false);
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  // Hooks
  const { data, isLoading, isError } = useGetStoreData(id, type, searchParams.get("designs_template_id") ? searchParams.get("designs_template_id") : null); // Get polotno store data from API
  // const [menuProps, toggleMenu] = useMenuState({ transition: true }); // For Context Menu

  useEffect(() => {
    if (!isError && data) {
      if (data.content) {
        store.loadJSON(JSON.parse(data.content));
      } else {
        store.addPage({
          width: data.type.width,
          height: data.type.height,
        });
      }

      setDpi(data.type.dpi);
      store.setSize(data.type.width, data.type.height);
    }
  }, [data, isError]);

  //  Listen to store changes (update page sidebar)
  useEffect(() => {
    return store.on('change', () => {
      setReload(!reload);
    });
  }, []);

  const getPageButton = () => {
    const pageCount = store.pages.length || 0;
    return (
      <button className="pages-toggle" onClick={() => setShowPageSidebar(true)}>
        <div className="pages-toggle__text">
          Pages {pageCount > 0 && <span className="pages-toggle__text--numbers">({pageCount})</span>}
        </div>
      </button>
    );
  };

  const handleOpenContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
        // Other native context menus might behave different.
        // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
        null,
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  // TODO: Temporary disable context menu
  // const contextMenuOptions: MenuOption[] = [
  //   {
  //     title: 'Copy',
  //     action: () => handleContextAction(ActionType.COPY),
  //   },
  //   {
  //     title: 'Cut',
  //     action: () => handleContextAction(ActionType.CUT),
  //   },
  //   {
  //     title: 'Paste',
  //     action: () => handleContextAction(ActionType.PASTE),
  //   },
  //   {
  //     title: 'Delete',
  //     action: () => handleContextAction(ActionType.DELETE),
  //     withDivider: true,
  //   },
  //   {
  //     title: 'Group',
  //     action: () => handleContextAction(ActionType.COMING_SOON),
  //   },
  //   {
  //     title: 'Ungroup',
  //     action: () => handleContextAction(ActionType.COMING_SOON),
  //     withDivider: true,
  //   },
  //   {
  //     title: 'Merge Fields',
  //     action: () => handleContextAction(ActionType.MERGE_FIELD),
  //     withDivider: true,
  //   },
  //   {
  //     title: 'Arrange',
  //     action: () => handleContextAction(ActionType.COMING_SOON),
  //   },
  //   {
  //     title: 'Lock/Unlock',
  //     action: () => handleContextAction(ActionType.TOGGLE_LOCK),
  //     withDivider: true,
  //   },
  //   {
  //     title: 'Add to Element Library',
  //     action: () => handleContextAction(ActionType.ADD_TO_ELEMENT),
  //   },
  // ];

  const handleContextAction = (action: ActionType, mergeField?: any) => {
    switch (action) {
      case ActionType.COPY: {
        // TODO: Temp duplicate. create memory for copy/cut/paste
        if (!isEmpty(store.selectedElements)) {
          let elementCopy = cloneDeep(store.selectedElements[0]) as any;
          delete elementCopy.id;
          store.activePage?.addElement(elementCopy);
        }
        break;
      }
      case ActionType.CUT: {
        // TODO: clipboard func.
        break;
      }
      case ActionType.PASTE: {
        // TODO: clipboard func.
        break;
      }
      case ActionType.DELETE: {
        // TODO: Dialog
        store.deleteElements(store.selectedElementsIds);
        break;
      }
      case ActionType.MERGE_FIELD: {
        // TODO: func.
        break;
      }
      case ActionType.TOGGLE_LOCK: {
        // store.selectedElements.forEach((element) => (element.locked = !element.locked));
        break;
      }
      case ActionType.ADD_TO_ELEMENT: {
        // TODO: func.
        break;
      }
      case ActionType.SHOW_RULER: {
        store.toggleRulers();
        break;
      }
      case ActionType.COMING_SOON: {
        toast.info('Coming soon!', {
          position: 'bottom-center',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    setContextMenu(null);
  };

  const getPageSidebar = () => {
    const duration = 150;

    // Convert styles to module and try to append
    const defaultStyle = {
      transition: `opacity ${duration}ms ease-in-out`,
      opacity: 0,
      visibility: 'hidden',
    };
    const transitionStyles: any = {
      entered: { opacity: 1, visibility: 'visible' },
      exited: { opacity: 0, visibility: 'hidden' },
    };
    return (
      <Transition in={showPageSidebar} timeout={duration}>
        {(state: TransitionStatus) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            className="pagesidebar-container"
          >
            <PageSidebar
              entityType={type}
              entityId={id}
              reload={reload}
              setReload={setReload} // set to !reload to refresh
              isLoading={isLoading}
              hidePanel={() => setShowPageSidebar(false)}
              store={store}
              entityData={data}
            />
          </div>
        )}
      </Transition>
    );
  };

  const ActionControls = ({store} : {store: any}) => {
    return <div>{data && <LockMenu store={store} entityData={data} />}</div>;
  }

  return (
    <>
      <OverlayLoader isLoading={isLoading}/>
      <div className="editor">
        <ToastContainer />
        <Sidebar store={store} design={{ id, type }} typeId={type === EntityTypes.DESIGN ? data?.type?.uuid : ''} entityData={data} />
        <div className="editor__content">
          <Header store={store} design={{ id, type, dpi }} entityData={data} onShowGrid={() => { setIsGridHidden(false) }} />
          <GridToolbar store={store} isHidden={isGridHidden} onHideGrid={() => {setIsGridHidden(true)}} />
          <Toolbar store={store} hideLock={true} components={{ActionControls}}/>
          {/* <CustomToolbar store={store} entityData={data} /> */}
          <div className="workspace" onContextMenu={handleOpenContextMenu}>
            {/* {getContextMenu()} TODO: Context menu func. w/ MUI */}
            <Workspace
              store={store}
              backgroundColor="#EEF0F2"
              activePageBorderColor="#37aae1"
              components={{
                PageControls: () => null,
                NoPages: () => '',
              }}
            />
            <div className="float-buttons">
              <ZoomButtons store={store} />
              {!showPageSidebar && getPageButton()}
            </div>
            {getPageSidebar()}
          </div>
        </div>
        {/* <Menu
        showMenu={contextMenu !== null}
        type="context"
        options={contextMenuOptions}
        onClose={() => handleCloseContextMenu()}
        coords={contextMenu}
        miscMenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }}
      /> */}
      </div>
    </>
  );
};
