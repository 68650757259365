// @ts-nocheck
import Konva from "konva";
import { API } from '../api';
interface PolotnoElement {
  type: string;
  id: string;
  x: number;
  y: number;
}

let count = 0;
const getId = () => {
  count += 1;
  return count.toString();
};

function getSize(doc) {
  const viewBox = doc.getAttribute("viewBox");
  const [x, y, width, height] = viewBox?.split(" ") || [];
  if (!doc.getAttribute("width")) {
    doc.setAttribute("width", width + "px");
  }

  if (!doc.getAttribute("height")) {
    doc.setAttribute("height", height + "px");
  }
  return {
    width: parseFloat(doc.getAttribute("width") || width),
    height: parseFloat(doc.getAttribute("height") || height)
  };
}

function getTransform(svgNode: SVGElement) {
  const m = svgNode.transform.baseVal.consolidate()?.matrix || new DOMMatrix();
  return new Konva.Transform([m.a, m.b, m.c, m.d, m.e, m.f]);
}

function getAbsoluteTransform(svgNode: SVGElement): Konva.Transform {
  const tr = getTransform(svgNode);
  if (svgNode.parentNode && svgNode.parentNode.nodeName !== "svg") {
    const parentTransform = getAbsoluteTransform(svgNode.parentNode);
    return parentTransform.multiply(tr);
  }
  return tr;
}

function getAbsoluteAttrs(svgNode: SVGElement) {
  return getAbsoluteTransform(svgNode).decompose();
}

function getAbsolutePosition(svgNode: SVGElement) {
  const { x, y } = svgNode.getBoundingClientRect();
  if (svgNode.nodeName === "svg") {
    return { x, y };
  }
  const parentPos = getAbsolutePosition(svgNode.parentNode as SVGElement);
  return {
    x: x + parentPos.x,
    y: y + parentPos.y
  };
}

function hasText(svgNode: SVGElement) {
  return svgNode.getElementsByTagName("text").length > 0;
}

function hasImage(svgNode: SVGElement) {
  const rects = [...svgNode.getElementsByTagName("rect")];
  const rectLikeImage = rects.find((rect) => {
    return rect.getAttribute("fill").indexOf("url") >= 0;
  });
  return svgNode.getElementsByTagName("image").length > 0 || rectLikeImage;
}

export function svgToURL(s) {
  const uri = window.btoa(unescape(encodeURIComponent(s)));
  return "data:image/svg+xml;base64," + uri;
}

const isSmallGroup = (svgNode: SVGElement) => {
  return (
    svgNode.nodeName === "g" &&
    svgNode.getBBox().width < 450 &&
    svgNode.getBBox().height < 450
  );
};

function getQuery(str = "url(#pattern)") {
  return str.replace(/url\(([^)]+)\)/, "$1").replace(/'/g, "");
}

function simplifyFontFamily(fontFamily: string) {
  if (fontFamily.includes("OpenSans")) {
    return "Open Sans";
  }
  if (fontFamily.includes("Montserrat")) {
    return "Montserrat";
  }
  const parts = fontFamily.split(",").map((s) => s.trim());
  return parts[1] || parts[0];
}

function getRoot(svgNode: SVGElement) {
  if (svgNode.parentNode && svgNode.parentNode.nodeName === "svg") {
    return svgNode.parentNode;
  } else {
    return getRoot(svgNode.parentElement as SVGElement);
  }
}

function recursiveGetElements(svgNode: SVGElement) {
  if (svgNode.nodeName === "defs") {
    return [];
  }

  // if (svgNode.getAttribute('id') === 'Agent_Icon-2') {
  //   debugger;
  // }
  if (svgNode.nodeName === "text") {
    const lines = Array.from(svgNode.children).map((child) => child.innerHTML);
    const text = lines.join("\n");

    const fontSize = parseFloat(svgNode.getAttribute("font-size") || "20");
    const { x, y, rotation } = getAbsoluteAttrs(svgNode);
    return [
      {
        type: "text",
        text,
        id: getId(),
        x: x + svgNode.getBBox().x,
        y: y + svgNode.getBBox().y,
        fontSize,
        rotation,
        lineHeight: svgNode.getBBox().height / lines.length / fontSize,
        width: svgNode.getBBox().width,
        height: svgNode.getBBox().height,
        fontFamily: simplifyFontFamily(svgNode.getAttribute("font-family")),
        fontWeight: svgNode.getAttribute("font-weight") || "normal",
        fontStyle: svgNode.getAttribute("font-style") || "normal",
        align: "left",
        fill: svgNode.getAttribute("fill") || "black"
      }
    ];
  }

  if (svgNode.nodeName === "rect") {
    const { x, y, rotation } = getAbsoluteAttrs(svgNode);
    const width = parseFloat(svgNode.getAttribute("width") || "0");
    const height = parseFloat(svgNode.getAttribute("height") || "0");
    const fill = svgNode.getAttribute("fill") || "";
    const refFill = fill.indexOf("url") >= 0;
    const query = refFill && getQuery(fill);
    const pattern = document.querySelector(query);
    const imageFill = pattern?.querySelector("image");
    if (imageFill) {
      const src = imageFill.getAttribute("xlink:href");
      return [
        {
          type: "image",
          id: getId(),
          x,
          y,
          width,
          height,
          src,
          rotation
        }
      ];
    }
    svgNode.setAttribute("transform", "");
    const svgTemplate = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">${pattern?.outerHTML}${svgNode.outerHTML}</svg>`;
    return [
      {
        type: "svg",
        id: getId(),
        x: x + svgNode.getBBox().x,
        y: y + svgNode.getBBox().y,
        width,
        height,
        src: svgToURL(svgTemplate)
      }
    ];
  }
  if (
    svgNode.nodeName === "path" ||
    svgNode.nodeName === "polygon" ||
    svgNode.nodeName === "circle"
  ) {
    const { x, y, rotation } = getAbsoluteAttrs(svgNode);
    const bbox = svgNode.getBBox();
    const { width, height } = bbox;
    svgNode.setAttribute("transform", "");
    const fill = svgNode.getAttribute("fill") || "";
    const refFill = fill.indexOf("url") >= 0;
    const query = refFill && getQuery(fill);
    const pattern = document.querySelector(query);

    const svgTemplate = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}"><defs>${
      pattern?.outerHTML
    }</defs><g transform="translate(${-bbox.x} ${-bbox.y})">${
      svgNode.outerHTML
    }</g></svg>`;

    return [
      {
        type: "svg",
        id: svgNode.getAttribute("id") || getId(),
        x: x + bbox.x,
        y: y + bbox.y,
        width,
        height,
        rotation,
        src: svgToURL(svgTemplate)
      }
    ];
  }

  if (isSmallGroup(svgNode) && !hasText(svgNode) && !hasImage(svgNode)) {
    const { x, y } = svgNode.getBBox();
    const absPos = getAbsolutePosition(svgNode);
    const { width, height } = svgNode.getBBox();
    const allChildren = Array.from(svgNode.querySelectorAll("*"));
    let minX = x;
    let minY = y;
    allChildren.forEach((child: SVGElement) => {
      if (child.nodeName === "g") {
        return;
      }
      const box = child.getBBox();
      minX = Math.min(minX, box.x);
      minY = Math.min(minY, box.y);
    });

    const clientRect = svgNode.getBoundingClientRect();
    const svg = getRoot(svgNode);
    const totalBox = svg?.getBoundingClientRect();
    const oldTransform = svgNode.getAttribute("transform");
    svgNode.setAttribute("transform", "");
    const svgTemplate = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}"><g transform="translate(${-x} ${-y})">${
      svgNode.outerHTML
    }</g></svg>`;

    svgNode.setAttribute("transform", oldTransform);
    const root = getRoot(svgNode);
    const rootSize = getSize(root);
    return [
      {
        type: "svg",
        id: getId(),
        x: ((clientRect.x - totalBox.x) / totalBox.width) * rootSize.width,
        y: ((clientRect.y - totalBox.y) / totalBox.height) * rootSize.height,
        width,
        height,
        name: svgNode.getAttribute("id") || "",
        src: svgToURL(svgTemplate)
      }
    ];
  }

  if (!hasText(svgNode) && !hasImage(svgNode)) {
    const elements = [];
    Array.from(svgNode.children).forEach((child) => {
      elements.push(...recursiveGetElements(child));
    });
    return elements;
  }

  const elements: Array<PolotnoElement> = [];

  Array.from(svgNode.children).forEach((child) => {
    elements.push(...recursiveGetElements(child));
  });
  return elements;
}

export const parseSVG = async(svgString) => {
  const div = document.createElement("div");
  document.body.appendChild(div);
  div.innerHTML = svgString;
  const doc = div.querySelector("svg");

  const { width, height } = getSize(doc);
  let elements: Array<PolotnoElement> = [];
  Array.from(doc.children).forEach((child: SVGElement) => {
    elements.push(...recursiveGetElements(child));
  });
  div.parentElement.removeChild(div);

  elements = await Promise.all(elements.map(async (element) => {
    if (element.type === 'svg') {
      const upload = await API.post('/files/upload/base64', {
        file: element.src,
      });

      if (upload?.data?.url && upload?.data?.size) {
        if (upload.data.size > 100000) {
          element.type = 'image';
          element.src = upload?.data?.url;
        } else {
          element.type = 'svg';
          element.src = upload?.data?.url;
          element.keepRatio = false;
        }
      }
    }

    if (element.type === 'image') {
      const upload = await API.post('/files/upload/base64', {
        file: element.src,
      });

      if (upload?.data?.url && upload?.data?.size) {
        element.type = 'image';
        element.src = upload?.data?.url;
      }
    }

    return element;
  }));

  // return doc
  return {
    width,
    height,
    pages: [{ id: getId(), children: elements }]
  };
}
