import { useInfiniteQuery, useQuery } from 'react-query';
import { API } from '../api';
import { API_ENDPOINTS } from '../constants/api';

const IMAGES_PER_PAGE = 25;

const fetchElementsList = async () => {
  const { data } = await API.get(`${API_ENDPOINTS.ELEMENTS}`);
  return data;
};

const fetchTemplatesList = async (typeId: string) => {
  const { data } = await API.get(`${API_ENDPOINTS.TEMPLATES}?designs_type_id=${typeId}`);
  return data;
};

const fetchShapesList = async () => {
  const { data } = await API.get(`${API_ENDPOINTS.SHAPES}`);
  return data;
};

const fetchStockImages = async (search: string, page: number = 1) => {
  const queryParams = new URLSearchParams({
    query: search,
    page: page.toString(),
    perPage: IMAGES_PER_PAGE.toString(),
  });

  const { data } = await API.get(`/unsplash/search?${queryParams.toString()}`);
  return data;
};

export const useElementsList = () => {
  return useQuery('elements', fetchElementsList);
};

export const useTemplatesList = (typeId: string) => {
  return useQuery('templates', () => fetchTemplatesList(typeId));
};

export const useShapesList = () => {
  return useQuery('shapes', fetchShapesList);
};

export const useStockImages = (search: string, page: number) => {
  return useQuery(['stockImages-single', page], () => fetchStockImages(search, page));
};

export const useInfiniteStockImages = (search: string) => {
  return useInfiniteQuery('stockImages', async ({ pageParam = 1 }) => fetchStockImages(search, pageParam), {
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < IMAGES_PER_PAGE) {
        return undefined;
      }
      return allPages.length + 1;
    },
  });
};
