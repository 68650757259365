export const enum EntityTypes {
  DESIGN = 'design',
  TEMPLATE = 'template',
  ELEMENT = 'element',
}
export const enum AssetTypes {
  USER = 'user',
  OFFICE = 'office',
  CLIENT = 'client',
  GROUP = 'group',
}
